import React from 'react';
import { IonApp, setupIonicReact } from '@ionic/react';
import { _store, _update } from '_Store';
import { func, axius, types, services } from 'utils';
import { Loading } from 'components';
import Rautes from 'Rautes';

// ::: plugins
import OneSignal from 'onesignal-cordova-plugin';
import { StatusBar } from '@awesome-cordova-plugins/status-bar';
import { HeaderColor } from '@awesome-cordova-plugins/header-color';

import 'assets/scss/custom.scss';

setupIonicReact();

const App: React.FC = () => {

  const { __auth: { user, isLoggedIn }, __data: { countries, active_venue, location, cache } } = _store.useState();

  const [loading, setLoading] = React.useState(true);


  React.useEffect(() => {
    console.log("App Launched!");
    const [navigationEntry] = performance.getEntriesByType("navigation") as PerformanceNavigationTiming[];
    const launchType = navigationEntry?.type === "navigate" ? "cold_start" : "warm_start";
    window.gtag?.("event", "app_launch", { launch_type: launchType });
  }, [])
  React.useEffect(() => {
    if (func.isMobile) {
      // 
      HeaderColor.tint('#B6101D');
      StatusBar.styleDefault();
      StatusBar.overlaysWebView(false);
      StatusBar.backgroundColorByHexString('#B6101D');
      // Onesignal
      OneSignal.setAppId(process.env.REACT_APP_ONESIGNAL_ID as string);
      OneSignal.setNotificationOpenedHandler(function (jsonData) {
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      });
      OneSignal.setEmail(user.email);
      OneSignal.setSMSNumber(user.phone_number);
      OneSignal.setExternalUserId(user.uuid);
    }

    if (countries.length === 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    userDetails();
    getSettings();

    setInterval(() => {
      getSettings();
    }, 600 * 1000); // 10 minutes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    cache.user && userDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cache.user]);

  React.useEffect(() => {
    if (active_venue.uuid) {
      func.getStorage('new_venue') && setLoading(true);
      services.categories(active_venue).then(categories => {
        _update.data('categories', categories
          .sort((a, b) => +a.sequence - +b.sequence)
          .filter((ctg) => !ctg.name.toLowerCase().includes('other'))
        );
        setLoading(false);
        func.delStorage('new_venue');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_venue]);

  React.useEffect(() => {
    const isLogin = func.getStorage('after_login');
    //  && !window.location.search
    if (location.address.lat) {
      !isLogin && func.config.env !== 'local' && setLoading(true);
      const oldVenue = func.copyObject(active_venue)?.uuid;
      axius.post(`search`, {
        city: location.address.city,
        range: 30,
        limit: 1,
        dataKey: 'venues',
        latitude: location.address.lat,
        longitude: location.address.lng,
        results_type: ['venues'],
        delivery_optionsX: [location.option],
      }).then(res => {
        if ((res.venues || []).length > 0) {
          if (!oldVenue && !isLogin && isLoggedIn) {
            func.setStorage('new_venue', 'yes');
            // window.location = '/';
            _update.data('active_venue', res.venues[0]);
          }
          _update.data('venues', res.venues);
        } else {
          const activeVenue = func.getStorageJson('active_venue');
          if (!activeVenue.uuid) {
            func.delStorage('active_venue');
            _update.data('active_venue', {});
            // func.redirect('/');
          }
        }
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.address.lat]);

  const userDetails = () => {
    Promise.all([
      axius.get(`users/${user.uuid}`),
      axius.get(`users/${user.uuid}/favourites`, {}),
      axius.get(`promotions`),
    ]).then(res => {
      if (res[0].status === 401 && isLoggedIn) {
        _update.auth('user', {});
        _update.auth('token', '');
        func.init();
        func.redirect('/login');
      }
      res[0].status === 200 && _update.auth('user', res[0].data);
      res[2].status === 200 && _update.auth('favourites', res[2].data);
      _update.data('promotions', ((res[2].data || []) as types.Promotion[]).filter(promo => promo.venues.includes(active_venue.uuid) && promo.is_active && promo.providers.includes('tomati')));
      setLoading(false);
    });
  }

  const getSettings = () => {
    axius.get('settings').then(res => {
      res.status === 200 && _update.data('countries', res.data.countries);
      res.status === 200 && _update.data('delivery_partners', res.data.delivery_partners);
      const oldVersion = func.versionBreak(func.app.version);
      const newVersion = func.versionBreak(res.data?.version?.mobile);
      if (!func.isLocal && !func.isMobile && newVersion > oldVersion) {
        console.log(`new Version: ${res.data.version.mobile}`);
        caches.keys().then(keys => {
          Promise.all(keys.map(key => caches.delete(key))).then(() => {
            func.redirect('./');
          });
        });
      }
    });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <IonApp>
      <Rautes />
    </IonApp>
  );
}

export default App;
