import React from "react";
import { IonContent, IonPage, useIonAlert, useIonToast } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { Header, IonLoading, Quantity, TabsOffset } from "components";
import { axius, cartFx, func, images, types } from "utils";
import { Alert, Button, ButtonGroup } from "react-bootstrap";
import moment from "moment";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
import { _store, _update } from "_Store";

import Cart2 from "./Cart2";
import Checkout from "./Checkout";

interface Props extends RouteComponentProps, types.StateProps {}
const Cart: React.FC<Props> = (props) => {
	const history = props.history;

	const [presentAlert] = useIonAlert();
	const [presentToast] = useIonToast();

	const {
		__auth: { user },
		__data: { cart, location, cartDefault, options, active_venue, promotions }
	} = _store.useState();

	const [step, setStep] = React.useState(1);
	const [loading, setLoading] = React.useState("");

	// const eta = cart.items.find((ci: types.Item) => moment.duration(ci.preparation_time).asMinutes() === Math.max(...cart.items.map((ci: types.Item) => { return moment.duration(ci.preparation_time).asMinutes(); })));
	const deliveryDistance = +func.distanceMatrix(
		{ lat: location?.address?.lat, lng: location?.address?.lng },
		{ lat: active_venue?.address?.latitude, lng: active_venue?.address?.longitude },
		true
	);
	const minCartValue =
		location.option !== "delivery" || cartFx.total(cart.items) >= +(cart.venue?.delivery?.cart_minimum || 0);

	React.useEffect(() => {
		active_venue?.uuid && getVenue();
		window.scroll(0, 0);

		const promoInterval = setInterval(() => {
			Promise.all([axius.get(`promotions`)]).then((res) => {
				_update.data(
					"promotions",
					((res[0].data || []) as types.Promotion[]).filter(
						(promo) => promo.venues.includes(active_venue.uuid) && promo.is_active && promo.providers.includes("tomati")
					)
				);
			});
		}, 30 * 1000);
		return () => {
			clearInterval(promoInterval);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		return () => {
			if (!props.history.location.pathname.includes("/cart")) {
				setStep(1);
				setLoading("");
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.history.location.pathname]);

	React.useEffect(() => {
		if (history.location.pathname !== "/cart") {
			setStep(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location.pathname]);

	React.useEffect(() => {
		active_venue?.uuid && getVenue();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (cart.items.length > 0) {
			const cartItems: types.ItemCart[] = func.copyObject(cart.items);
			for (let i = 0; i < cartItems.length; i++) {
				const item = cartItems[i];
				let price_total = item.price_discount || item.price;
				price_total = price_total * (item.quantity || 1);

				for (let v = 0; v < Object.keys(item.variants).length; v++) {
					const op = Object.keys(item.variants)[v];
					for (let j = 0; j < (item.variants[op] || []).length; j++) {
						const iop = item.variants[op][j];
						if (+iop.price > 0) {
							price_total = price_total + +iop.price * +iop.quantity;
						}
					}
				}
				const hasPromo = promotions.find(
					(promo) =>
						promo.items.includes(item.code) ||
						(item.venue_categories?.length > 0 && promo.categories.includes(item.venue_categories[0].uuid))
				);
				let discount = 0;
				if (hasPromo) {
					if (hasPromo.type.includes("FLAT")) {
						discount = +hasPromo.value;
					}
					if (hasPromo.type.includes("PERCENTAGE")) {
						discount = price_total * (+hasPromo.value / 100);
					}
					cartItems[i]["discount"] = discount;
					cartItems[i]["promotion"] = hasPromo.uuid;
				}
				cartItems[i]["price_total"] = price_total;
				cartItems[i]["description"] = item.description;
				// item.code === '2' && console.log(cartItems[i], price_total);
			}
			_update.data("cart", { ...cart, items: cartItems });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cart.items]);

	const getVenue = (onOk: any = null) => {
		axius.get(`venues/${active_venue?.uuid}/items`).then((res) => {
			let o = JSON.parse(JSON.stringify(res));
			if (onOk) {
				onOk(o.data, o.venue);
			} else {
				const kart = func.getStorageJson("cart");
				_update.data("cart", { ...kart, venue: res.venue });
			}
		});
	};

	const updateCart = (item: types.ItemCart, increment: "+" | "-") => {
		const doUpdate = () => {
			_update.data("cart", { ...cart, items: cartFx.add(item, cart.items, increment) });
		};
		if (cart.items.length === 1 && increment === "-") {
			if (window?.gtag) {
				window.gtag("event", "order_cancel");
			}
		}
		if (item.quantity === 1 && increment === "-") {
			presentAlert({
				header: `Remove`,
				message: `Do you want to remove this product from your cart?`,
				buttons: [
					{ text: `Cancel` },
					{
						text: `Remove`,
						handler: () => {
							_update.data("cart", {
								...cart,
								items: cartFx.remove(item, cart.items),
								venue: cart.items.length === 0 ? {} : active_venue
							});
						}
					}
				]
			});
		} else {
			doUpdate();
		}
	};

	const findVariants = (codes: string[]) => {
		return cart.items
			.map((item) => {
				return Object.keys(item.variants)
					.map((v) => {
						return (item.variants as any)[v].map((iv: any) => {
							return iv;
						});
					})
					.flat();
			})
			.flat()
			.filter((as) => codes.includes(as.code))
			.map((as) => as.option)
			.join(", ");
	};

	const checkout = (deliveryFee: number, accepted?: boolean) => {
		if ((location.option === "delivery" && minCartValue) || location.option === "pickup") {
			if (window?.gtag) {
				window.gtag("event", "begin_checkout", {
					value: cartFx.total(cart.items),
					delivery_fee: deliveryFee,
					currency: "NGN",
					items: cart.items.map((item) => {
						return {
							id: item.id,
							item_name: item.name,
							price: item.price_total,
							quantity: item.quantity
						};
					})
				});
			}
			window.scroll(0, 0);
			if (step === 3) {
				if (user.uuid) {
					if (user.email) {
						if (cart.payType) {
							if (cart.delivery.channel !== "cr" || (cart.delivery.channel === "cr" && accepted)) {
								presentAlert({
									header: `Checkout`,
									message: `Are you ready to complete your order?`,
									buttons: [
										{ text: `Cancel` },
										{
											text: `Place Order`,
											handler: () => {
												setLoading(`placing order...`);
												getVenue((items: types.Item[], venue: types.Venue) => {
													_update.data("active_venue", venue);
													if (venue.preferences.can_take_orders && venue.preferences.online) {
														const discount = cart.items.reduce((a, b) => a + (b.discount || 0), 0);
														const refreshedItems =
															items.filter((men) =>
																cart.items.map((ci: types.ItemCart) => ci.uuid).includes(men.uuid)
															) || [];
														const cantDeliver =
															refreshedItems.filter(
																(ri) => !(ri.venue_categories[0] || {}).delivery || !ri.can_deliver
															) || [];
														const notPublished = refreshedItems.filter((ri) => ri.status === "draft") || [];
														const notAvailable =
															refreshedItems.filter(
																(ri) =>
																	!(venue.preferences.items_available || refreshedItems.map((ri) => ri.code)).includes(
																		ri.code
																	)
															) || [];
														// console.log({ cantDeliver, notPublished, notAvailable, refreshedItems }, venue.preferences.items_available);
														if (
															(cantDeliver.length === 0 || location.option === "pickup") &&
															notPublished.length === 0 &&
															notAvailable.length === 0
														) {
															const allSides = cart.items
																.map((item: types.ItemCart) => {
																	return Object.keys(item.variants)
																		.map((v) => {
																			return (item.variants as any)[v]
																				.map((iv: any) => {
																					return iv.code;
																				})
																				.flat();
																		})
																		.flat();
																})
																.flat()
																.filter((as: string) => as);
															const availableSides = venue.preferences.sides_available || [];
															const sidesUnavailables =
																availableSides.length > 0
																	? allSides.filter((as: string) => !availableSides.includes(as))
																	: [];
															if (sidesUnavailables.length === 0) {
																const order = {
																	type: location.option,
																	items: cart.items,
																	user: user.uuid,
																	venue: active_venue?.uuid,
																	address: {
																		...location.address,
																		lat: location.address.lat.toString(),
																		lng: location.address.lng.toString()
																	},
																	status: cart.payType === "advance" ? 12 : 0,
																	paytype: cart.payType,
																	delivery: cart.delivery,
																	amount: {
																		total: cartFx.total(cart.items) + deliveryFee,
																		amount: cartFx.total(cart.items),
																		discount,
																		delivery: deliveryFee
																	}
																};
																axius.post("orders", order).then(async (res: types.AxiusResponse) => {
																	// setLoading(''); return;
																	if (res.status === 200) {
																		_update.data("cache", { user: res.data.uuid });
																		if (window?.gtag) {
																			window.gtag("event", "purchase", {
																				value: cartFx.total(cart.items),
																				delivery_fee: deliveryFee,
																				currency: "NGN",
																				transaction_id: res.data.id,
																				items: cart.items.map((item) => {
																					return {
																						id: item.id,
																						item_name: item.name,
																						price: item.price_total,
																						quantity: item.quantity
																					};
																				})
																			});
																		}
																		if (cart.payType === "advance") {
																			if (res.status === 200) {
																				if (func.isMobile) {
																					const browser = InAppBrowser.create(res.pay.checkout_url);
																					browser.on("loadstop").subscribe((event) => {
																						if (event.url.includes("/webhook/")) {
																							browser.close();
																							props.history.replace(
																								`/webhook/${event.url.replace("https://", "").split("/")[2]}`
																							);
																							window.location.reload();
																						}
																					});
																				} else {
																					window.location = res.pay.checkout_url;
																				}
																			} else {
																				presentAlert(res.message);
																			}
																		} else {
																			setStep(1);
																			_update.data("cart", cartDefault);
																			_update.data("order", res.data);
																			presentToast("Your order has been placed!", 1500);
																			setTimeout(() => {
																				props.history.replace(`/user/orders/${res.data.id}`);
																				// window.location.reload();
																			}, 100);
																		}
																	} else {
																		setLoading("");
																		presentAlert(res.message || "Error");
																	}
																});
															} else {
																setLoading("");
																presentAlert(
																	`We're sorry. We run out of the following item(s): ${findVariants(sidesUnavailables)}`
																);
															}
														} else {
															setLoading("");
															presentAlert(`
                            We're sorry. The following item(s) are not available for 
                            ${location.option}: ${[...cantDeliver, ...notPublished, ...notAvailable]
																.map((cd) => {
																	return cd.name;
																})
																.join(", ")}
                          `);
														}
													} else {
														setLoading("");
														presentAlert(`We're sorry. We are not taking any orders at this moment`);
													}
												});
											}
										}
									]
								});
							} else {
								presentAlert(`Please accept the RepDash T&Cs`);
							}
						} else {
							presentAlert(`Please choose a payment option`);
						}
					} else {
						emailPrompt();
					}
				} else {
					authPrompt();
				}
			} else {
				continueCarting();
			}
		} else {
			presentAlert(
				`We require a minimum order of <b>₦${func.numberFormat(
					active_venue?.delivery.cart_minimum,
					2
				)}</b> to qualify for delivery.`
			);
		}
	};

	const authPrompt = () => {
		presentAlert({
			header: `Log in or sign up!`,
			message: `Login in to complete your order`,
			buttons: [
				{ text: `Cancel` },
				{
					text: `Continue`,
					handler: () => {
						props.history.push("/login");
					}
				}
			]
		});
	};

	const emailPrompt = () => {
		presentAlert({
			header: `Please complete your profile!`,
			message: `Login in to complete your order`,
			buttons: [
				{ text: `Cancel` },
				{
					text: `Set my email`,
					handler: () => {
						func.setStorageJson("edit_user", {
							from: props.location.pathname,
							edit: ["email"],
							option: "promos"
						});
						props.history.push("/user/account");
					}
				}
			]
		});
	};

	const continueCarting = () => {
		if (location.option === "delivery") {
			setLoading("calculating delivery fee...");
			axius
				.post("orders/delivery-fee", {
					city: location.address.city.toLowerCase(),
					venue: active_venue.uuid,
					amount: cartFx.total(cart.items),
					source: {
						lat: active_venue.address.lat,
						lng: active_venue.address.lng
					},
					destination: {
						lat: location.address.lat,
						lng: location.address.lng
					}
				})
				.then((res) => {
					if (res.data.length > 0) {
						_update.data("delivery_fees", res.data);
						if (res.data.length === 1) {
							_update.data("cart", {
								...cart,
								delivery: { ...cart.delivery, channel: res.data[0].partner },
								deliveryFee: res.data[0].amount,
								payType: ""
							});
						} else {
							_update.data("cart", {
								...cart,
								delivery: { ...cart.delivery, channel: "" },
								deliveryFee: 0,
								payType: ""
							});
						}
						setStep(3);
					} else {
						// presentAlert(`We do not deliver to ${location.address.city}. Please change your location.`);
						presentAlert(
							`Oops! Our riders are all on the run right now 🏃🏽💨. Sorry for the wait—please try again in a few minutes!`
						);
					}
					setLoading("");
				});
		} else {
			_update.data("delivery_fees", []);
			_update.data("cart", { ...cart, delivery: { ...cart.delivery, channel: "" }, deliveryFee: 0, payType: "" });
			setStep(3);
			// setStep((s) => s + 1);
		}
	};

	const deliveryTime = {
		from: active_venue.delivery?.schedule?.from || "09:00",
		to: active_venue.delivery?.schedule?.to || "20:00"
	};

	const goToItem = async (item: types.Item, itemLink: string) => {
		_update.data("item", item);
		props.history.push(itemLink);
	};

	return (
		<IonPage id="Cart">
			<Header
				{...props}
				showSearch={false}
				showSegments={false}
				showBack={step > 1}
				goBack={() => {
					if (step === 1) {
						props.history.goBack();
					} else {
						setStep(1);
						// step === 3 && location.option === 'delivery' && Object.keys(delivery_partners).length === 1
						// if (location.option === 'delivery') {
						//   setStep(1);
						// } else {
						//   setStep((s) => s - 1);
						// }
					}
				}}
			/>
			<IonContent fullscreen>
				<IonLoading open={loading ? true : false} message={loading} onCancel={() => setLoading("")} />

				{step === 1 && (
					<div id="Cart" className="content c-small">
						{cart.items.length > 0 && (
							<div>
								<div>&nbsp;</div>
								<div id="Segments" className="mb-3 d-flex justify-content-center">
									<ButtonGroup>
										{options.map((seg) => (
											<Button
												key={seg.key}
												variant={location.option === seg.key ? "active" : ""}
												onClick={() => _update.data("location", { option: seg.key })}
											>
												{seg.label}
											</Button>
										))}
									</ButtonGroup>
								</div>
							</div>
						)}
						{location.option === "delivery" &&
							!moment().isBetween(func.dates.dt + " " + deliveryTime.from, func.dates.dt + " " + deliveryTime.to) && (
								<Alert variant="warning" className="small text-xs">
									Delivery is currently not available
								</Alert>
							)}
						<div className="divider hide" />

						{cart.items.length > 0 && (
							<>
								<div className="px-4">
									<h6 className="m-0 fw-500 primary- text-uppercases">{func.venueName(cart.venue)}</h6>
									{!minCartValue && (
										<div className="text-center mt-3">
											<Alert variant="danger">
												We require a minimum order of <b>₦{func.numberFormat(cart.venue?.delivery.cart_minimum, 2)}</b>{" "}
												to qualify for delivery.
												<div>
													<div
														className="pointer ins fw-500 mt-2"
														onClick={() => _update.data("location", { option: "pickup" })}
													>
														Change to pickup
													</div>
												</div>
											</Alert>
										</div>
									)}
								</div>
								<div className="divider hide" />
							</>
						)}

						<div className="p-4">
							{cart.items.length === 0 && (
								<div
									id="Loading"
									className="d-flex justify-content-center align-items-center"
									style={{ height: "63vh" }}
								>
									<div className="text-center">
										<img src={images.CartEmpty} alt="CartEmpty" height="90" />
										<div className="mt-2" style={{ fontSize: 20 }}>
											Your cart is empty!
										</div>
									</div>
								</div>
							)}

							{cart.items.length > 0 && (
								<div>
									{(cart.items || []).map((item) => {
										let discount = item.discount;
										let price_total = item.price_total;
										const itemLink = `/i/${func.seourl(item.name)}.${active_venue.uuid}.${item.code}`;
										const hasPromo = promotions.find(
											(promo) =>
												promo.items.includes(item.code) ||
												(item.venue_categories?.length > 0 && promo.categories.includes(item.venue_categories[0].uuid))
										);
										const isOrderMinimum = hasPromo && hasPromo.limit.minimum_order <= cartFx.total(cart.items);
										return (
											<div key={item.uuid} className="row">
												<div className="col-3">
													<div
														className="bg-img border"
														style={{
															backgroundImage: `url("${(item.images_links || [])[0] || images.Logo}")`,
															height: "8vh",
															borderRadius: 4
														}}
													>
														{hasPromo && (
															<div className="item-discount">
																<i className={`fa fa-gift primary ${isOrderMinimum ? "mr-1" : ""}`} />
																{isOrderMinimum &&
																	`-${hasPromo.type.includes("FLAT") ? "₦" : ""}${func.numberFormat(hasPromo.value)}${
																		hasPromo.type.includes("PERCENTAGE") ? "%" : ""
																	}`}
															</div>
														)}
													</div>
												</div>
												<div className="col-9">
													<div className="primary fw-600 text-truncate">{item.name}</div>
													{discount > 0 ? (
														<div className="d-flex align-items-center">
															<div className="smalls mb-2 mr-2">₦{func.numberFormat(price_total - discount)}</div>
															<del className="smalls mb-2 text-muted">₦{func.numberFormat(price_total)}</del>
														</div>
													) : (
														<div className="smalls mb-2">₦{func.numberFormat(price_total)}</div>
													)}
													{Object.keys(item.variants).map((op) => (
														<div key={op}>
															{item.variants[op].map((po: any) => (
																<div key={po.option} className="small text-muted">
																	{po.quantity > 1 ? `${po.quantity} x ` : ""} {po.option}{" "}
																	{po.price > 0 ? `(+₦${func.numberFormat(+po.price * +po.quantity)})` : ""}
																</div>
															))}
														</div>
													))}
													<div className="row align-items-center mt-3">
														<div className="col-6">
															{Object.keys(item.variants).length > 0 && (
																<div
																	className="small text-muted"
																	onClick={() => goToItem(item as unknown as types.Item, itemLink)}
																>
																	Edit Selections
																</div>
															)}
														</div>
														<div className="col-6">
															<Quantity
																value={item.quantity || 1}
																increase={() => updateCart(item, "+")}
																decrease={() => updateCart(item, "-")}
															/>
														</div>
													</div>
												</div>
												<div className="col-12 my-3 divider" />
											</div>
										);
									})}

									<div className="footers pb-3 mt-5">
										<Button
											variant={`${minCartValue ? "secondary" : "gray"} btn-block btn-lg text-uppercases`}
											onClick={() => checkout(deliveryDistance)}
										>
											<div className="d-flex justify-content-between">
												<div>Checkout</div>
												<div>
													<b>₦{func.numberFormat(cartFx.total(cart.items, true, true), 2)}</b>
												</div>
											</div>
										</Button>
									</div>

									<div className="text-center my-2">
										<span className="primary fw-600 ins" onClick={() => props.history.push(`/`)}>
											Continue Shopping
										</span>
									</div>
								</div>
							)}
						</div>
					</div>
				)}

				{step === 2 && <Cart2 {...props} checkout={checkout} minCartValue={minCartValue} />}

				{step === 3 && <Checkout {...props} checkout={checkout} minCartValue={minCartValue} />}
				<TabsOffset />
			</IonContent>
		</IonPage>
	);
};

export default Cart;
