import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { Button, Alert } from 'react-bootstrap';
import { axius, func, images, types } from 'utils';
import { Header, IonLoading, TabsOffset } from 'components';
import { Form, Input, Select } from 'antd';
import { _store } from '_Store';

const jQuery = (window as any).jQuery;

interface Props extends RouteComponentProps, types.StateProps { };
const Help: React.FC<Props> = props => {

    const { __data: { order } } = _store.useState();
    const [form] = Form.useForm();

    const [step, setStep] = React.useState(1);
    const [files, setFiles] = React.useState([]);
    const [imagis, setImages] = React.useState([]);
    const [errMsg, setErrMsg] = React.useState('');
    const [loading, setLoading] = React.useState('');

    const ord = order;
    const isLow = props.location.pathname.split('/')[3];
    const isOrder = props.location.pathname.split('/')[2];

    React.useEffect(() => {

    }, []);

    const readImage = (e: any) => {
        let fale = e.target.files[0];
        if (fale) {
            let reader = new FileReader();
            reader.onload = (r) => {
                setFiles(sf => (sf.concat(fale)));
                setImages(si => (si.concat(reader.result as any)));
            }
            reader.readAsDataURL(fale);
        }
    }

    const removeImage = (i: number) => {
        setFiles(sf => sf.filter((o: any, ii: number) => i !== ii));
        setImages(si => si.filter((o: any, ii: number) => i !== ii));
    }

    const submit = (v: any) => {
        setErrMsg('');
        setLoading('submitting...');
        if (isLow) {
            v['type'] = 'Incident';
        }
        if (isOrder) {
            v['order'] = isOrder;
        }
        axius.postFile(`tickets`, { ...v, files, }).then((res: types.AxiusResponse) => {
            if (res.status === 200) {
                setStep(2);
            } else {
                if (res.status === 412) {
                    setErrMsg(res.data.join('<br />'));
                } else {
                    setErrMsg(res.message);
                }
            }
            setLoading('');
        });
    }

    return (
        <IonPage id="Help">
            <Header {...props} showSearch={false} />

            <IonContent fullscreen>
                <IonLoading open={loading ? true : false} message={loading} onCancel={() => setLoading('')} />

                {step === 1 && (
                    <div className="p-4">
                        {isOrder && (
                            <h4 className="fw-600 mb-3 primary">Order #{ord.id}</h4>
                        )}
                        {isLow ? (
                            <h4 className="fw-600 mb-0">Oh No!, We were hoping for a better rating.</h4>
                        ) : (
                            <h4 className="fw-600 mb-0">How Can We Help?</h4>
                        )}
                        <div>&nbsp;</div>

                        {errMsg && (<Alert variant="danger" className="text-center">{errMsg}</Alert>)}

                        <Form form={form} layout="vertical" onFinish={submit} className="mt-4">
                            <Form.Item
                                label="Select your issue category"
                                name="type"
                                rules={[{ required: true, message: 'Please choose a category' },]}
                            >
                                <Select size="large" className="primary">
                                    {['Product Quality', 'Incomplete Order', 'Refund Request', 'Product Enquiry', 'Customer Service', 'Technical Issue'].map(option => (
                                        <Select.Option key={option} value={option}>{option}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label={isOrder ? 'How could we have made it better?' : 'Comment'} name="description" rules={[func.requiredInput]}>
                                <Input.TextArea size="large" rows={4} className="primary" />
                            </Form.Item>

                            <label>Upload files, if any. (You can upload up to 3 files)</label>
                            <div className="row">
                                {imagis.map((image, i) => (
                                    <div key={image} className="col-4">
                                        <div id="Upload" className="bg-img rounded" style={{ backgroundImage: `url(${image})` }}>
                                            <div className="rem" onClick={() => removeImage(i)}>x</div>
                                        </div>
                                    </div>
                                ))}
                                {imagis.length < 3 && (
                                    <div className="col-4">
                                        <div
                                            id="Upload"
                                            className="text-center d-flex align-items-center justify-content-center"
                                            onClick={() => jQuery('#upload_file').click()}
                                        >
                                            <div>
                                                <input type="file" accept={'image/*'} id="upload_file" className="hide" onChange={readImage} />
                                                <img src={images.Upload} alt="Upload" height={20} />
                                                <div className="small mt-2">Click here to upload</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <p>&nbsp;</p>

                            <Button type="submit" variant="primary-sec btn-block btn-md text-uppercases" style={{ height: 49 }}>
                                Submit
                            </Button>
                        </Form>
                    </div>
                )}

                {step === 2 && (
                    <div className="text-center d-flex justify-content-center align-items-center" style={{ height: '67vh', }}>
                        <div style={{ width: '90vw' }}>
                            <img src={images.Smiley} alt="Smiley" />
                            <div>&nbsp;</div>
                            <h4 className="fw-600 mb-0 primary">
                                Dear customer,
                            </h4>
                            <b className="fw-600 mb-0 primary">
                                We have received your feedback and a member of our team will be in touch shortly.
                            </b>
                            <div>&nbsp;</div>
                            <div className="text-muted">Your satisfaction is our priority.</div>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <Button type="submit" variant="secondary btn-block btn-md text-uppercases" style={{ height: 49 }} onClick={() => func.redirect('/')}>
                                Back to Home
                            </Button>
                        </div>
                    </div>
                )}
                <TabsOffset />
            </IonContent>
        </IonPage>
    );
};

export default Help;
