import React from 'react';
import { func } from 'utils';


interface Props {
    value: number;

    increase: () => void;
    decrease: () => void;
}
const Quantity = ({ value, increase, decrease }: Props) => {

    return (
        <React.Fragment>
            <div id="Quantity" className="d-flex justify-content-between align-items-center fs-12" style={{ maxWidth: 120 }}>
                <div className="bg-muted">
                    <div
                        style={{ padding: '3px 10px' }}
                        onClick={decrease}
                    >
                        <i className={`fa fa-${value === 1 ? 'trash-alt' : 'minus'}`} />
                    </div>
                </div>
                <div className="px-2 fw-500">{func.numberFormat(value, 0)}</div>
                <div>
                    <div
                        style={{ padding: '3px 10px', }}
                        onClick={increase}
                    >
                        <i className={`fa fa-plus`} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Quantity;