import React from 'react';
import Radio from './Radio';

interface StateProps {
    label: any;
    color?: 'primary' | 'secondary';
    selected: boolean;
    disabled?: boolean;
    className?: string;

    onClick: () => void;
};
interface Props extends StateProps { };
const CustomRadio: React.FC<Props> = ({ label, color, selected, disabled, className, onClick }) => {

    return (
        <React.Fragment>
            <div
                id="CustomRadio"
                className={`${className || 'p-3s mb-3s'} d-flex align-items-center pointer ${selected ? 'selected' : ''}`}
                style={{ opacity: disabled ? '.5' : 1 }}
                onClick={() => !disabled && onClick()}
            >
                <Radio color={color} selected={selected} height={22} />
                <div className={`ml-3 fs-14 ${selected ? 'fw-400' : ''}`} style={{ width: '100%' }}>{label}</div>
            </div>
        </React.Fragment >
    );
}

export default CustomRadio;