import React from 'react';
import { RouteComponentProps } from 'react-router';
import { useIonAlert, useIonToast } from '@ionic/react';
import { Button } from 'react-bootstrap';
import { func, cartFx, axius, types, images } from 'utils';
import { _store, _update } from '_Store';

interface StateProps {
    item: types.Item;
    layout: 'list' | 'grid';
    promo?: any;
    venue?: any;
    canAdd2Cart?: boolean;
    description?: boolean;
};
interface Props extends RouteComponentProps, types.StateProps, StateProps { };
const Item: React.FC<Props> = props => {

    const [presentAlert] = useIonAlert();
    const [presentToast] = useIonToast();

    const [addingToFavorite, setAddingToFavorite] = React.useState(false);

    const { item, layout, promo, } = props;
    const { __data: { cart, promo_cart, active_venue, promotions }, __auth: { user, isLoggedIn, favourites } } = _store.useState();

    React.useEffect(() => {
        // console.log(images[Math.floor(Math.random() * 5)]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const venue = active_venue || props.venue || item.venue || {};
    const isOpen = func.isOpen(venue);
    const itemLink = `/i/${func.seourl(item.name)}.${active_venue.uuid}.${item.code}`;
    const itemImage = ((item.images[0] ? item.images_links[0] : (venue?.logo ? active_venue.logo_link : images.Logo)) || '');

    const add2Cart = () => {
        let inCart = ((cart.items || []).find((c) => c.uuid === item.uuid) || {}) as types.Item;
        let toAdd = {
            id: item.id,
            uuid: item.uuid,
            code: item.code,
            name: item.name,
            price: +(inCart.price || item.price),
            images: item.images,
            images_links: item.images_links,
            free_sides: inCart.free_sides || [],
            paid_sides: inCart.paid_sides || [],
            price_total: +(inCart.price_total || item.price),
            description: item.description,
            variants: inCart.variants || {},
            preparation_time: item.preparation_time,
            venue_categories: item.venue_categories,
        };
        if (cart.venue) {
            if (!cart.venue.uuid || venue.uuid === cart.venue.uuid) {
                if ((item.free_sides || []).length > 0 || (item.paid_sides || []).length > 0 || item.variants.length > 0) {
                    goToItem();
                } else {
                    let nCart = JSON.parse(JSON.stringify(cart)) as types.Cart;
                    nCart['items'] = cartFx.add(toAdd as unknown as types.ItemCart, nCart.items);
                    nCart['venue'] = venue;
                    _update.data('cart', nCart);
                    presentToast(`${item.name} added to cart!`, 1500);
                }
            } else {
                presentAlert({
                    header: 'Sorry!',
                    message: `You already have item(s) at "${func.venueName(cart.venue)}". You can not add this item from "${func.venueName(venue)}".`,
                    buttons: [
                        { text: 'Okay', },
                        {
                            text: 'Empty cart', handler: () => {
                                const nCart = { items: [] as types.ItemCart[], venue: {} } as types.Cart;
                                nCart['items'] = cartFx.add(toAdd as unknown as types.ItemCart, nCart.items);
                                nCart['venue'] = venue;
                                _update.data('cart', nCart);
                                presentToast(`${item.name} added to cart!`, 1500);
                            }
                        }
                    ],
                });
            }
        } else {
            let nCart = { items: [] as types.ItemCart[], venue: {} } as types.Cart;
            nCart['items'] = cartFx.add(toAdd as unknown as types.ItemCart, nCart.items);
            nCart['venue'] = venue;
            _update.data('cart', nCart);
            presentToast(`${item.name} added to cart!`, 1500);
        }
    }

    const add2Favorite = () => {
        setAddingToFavorite(true);
        let favItems = func.copyObject(favourites) as types.Item[];
        favItems = favItems.concat(item);
        _update.auth('favourites', favItems);
        axius.post(`users/${user.uuid}/favourites`, { item: item.uuid }).then(res => {
            if (res.status === 200) {
                presentToast(`Item added to your favourites`, 1500);
            }
            setAddingToFavorite(false);
        });
    }

    const removeFav = () => {
        setAddingToFavorite(true);
        let favItems = func.copyObject(favourites) as types.Item[];
        favItems = favItems.filter(fav => fav.uuid !== item.uuid);
        _update.auth('favourites', favItems);
        axius.delte(`users/${user.uuid}/favourites/${item.uuid}`).then(res => {
            if (res.status === 200) {
                presentToast(`Item removed from your favourites`, 1500);
            }
            setAddingToFavorite(false);
        });
    }

    const goToItem = async () => {
        _update.data('item', item);
        _update.data('active_venue', venue);
        props.history.push(itemLink);
    }

    let inCart = (promo || {}).uuid ? (promo_cart.items || []).find((c: types.Item) => c.uuid === item.uuid) || {} : (cart.items || []).find((c: types.ItemCart) => c.uuid === item.uuid) || {} as types.Item;
    const inFav = favourites.find(fav => fav.uuid === item.uuid) || {} as types.Item;
    const canAdd2Cart = typeof props.canAdd2Cart !== 'undefined' ? props.canAdd2Cart : true;
    const itemAvailableInStore = !active_venue.preferences?.items_available || active_venue.preferences?.items_available.includes(item.code);
    const hasPricedVariants = item.variants.filter(iv => iv.options.filter(ivo => ivo.price > 0).length > 0);
    const hasPromo = promotions.filter(promo => promo.venues.includes(active_venue.uuid) && (promo.items.includes(item.code) || promo.categories.includes(item.venue_categories[0].uuid))).length > 0;

    return (
        <React.Fragment>
            {item.status === 'published' && itemAvailableInStore && (
                <div id="Item" className={`animate__animated animate__fadeIn pointer ${layout} ${item.uuid}`}>

                    {layout === 'grid' && (
                        <div id="grid" onClick={goToItem}>
                            <div className="bg-img" style={{ backgroundImage: `url("${itemImage}")`, borderBottom: '1px solid #eee' }}>
                                {hasPromo && (
                                    <div className="item-discount grid">
                                        <i className="fa fa-gift primary" />
                                    </div>
                                )}
                                <div className="price small fw-700">₦{func.numberFormat(item.price_discount | item.price)}{hasPricedVariants.length > 0 && '+'}</div>
                                {!isOpen && (
                                    <div className="closed d-flex align-items-center justify-content-center fw-600">C L O S E D</div>
                                )}
                            </div>
                            <div className="px-2 py-2">
                                <div className="name mb-1s" style={{ width: '100%' }}>
                                    <div className="row">
                                        <div className="col-12 text-truncate fw-500">{item.name}</div>
                                        {/* <div className="col-4">
                                                <Button variant="primary-light btn-xs tt-none" onClick={() => {}}>
                                                    + Add
                                                </Button>
                                            </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {layout === 'list' && (
                        <div id="list">
                            <div className="d-flex justify-content-betweens align-items-center">
                                <div className="bg-img" onClick={goToItem} style={{
                                    backgroundImage: `url("${itemImage}")`, width: 60, height: 60, borderRadius: 5, border: 0
                                }}>
                                    {!isOpen && (
                                        <div className="closed d-flex align-items-center justify-content-center fw-600">C L O S E D</div>
                                    )}
                                    {hasPromo && (
                                        <div className="item-discount">
                                            <i className="fa fa-gift primary" />
                                        </div>
                                    )}
                                </div>
                                <div className="pl-3" style={{ width: 'calc(100% - 60px)' }}>
                                    <div className="name fw-500 mb-1s text-truncate" onClick={goToItem}>
                                        {item.name}
                                    </div>
                                    {item.description && (
                                        <div className="small text-muted" onClick={goToItem} style={{ maxHeight: 35, overflow: 'hidden' }}>
                                            {item.description.substring(0, 70)}...
                                        </div>
                                    )}
                                    <div className="primary fw-500 mt-2" onClick={goToItem}>
                                        {hasPricedVariants.length > 0 && 'From '}₦{func.numberFormat(item.price_discount || item.price)}
                                    </div>
                                    <div className="small text-muted mb-1 d-flex align-items-center" onClick={goToItem}>
                                        {inCart.uuid && (
                                            <div className="fs-12 mr-2s text-muted fw-500">&nbsp;•&nbsp;In cart</div>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-betweens mt-2">
                                        {canAdd2Cart && !inCart.uuid && (
                                            <Button variant="primary-light btn-sm mr-2" onClick={add2Cart}>
                                                <small className="fw-500">+ Add</small>
                                            </Button>
                                        )}
                                        {inCart.uuid && (
                                            <Button variant="primary btn-sm mr-2" onClick={() => props.history.push('/cart')}>
                                                &nbsp;<small className="fw-500">Cart</small>&nbsp;
                                            </Button>
                                        )}
                                        &nbsp;
                                        {isLoggedIn && (
                                            <>
                                                {inFav.uuid ? (
                                                    <Button variant="clear btn-sm" disabled={addingToFavorite} onClick={removeFav}>
                                                        <img src={images.BookmarkFilled} alt="Remove Bookmark" height={24} />
                                                    </Button>
                                                ) : (
                                                    <Button variant="clear btn-sm" disabled={addingToFavorite} onClick={add2Favorite}>
                                                        <img src={images.Bookmark} alt="Bookmark" height={24} />
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
}

export default Item;