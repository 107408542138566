import React from 'react';
// import { IonRouterOutlet } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';
import { _store } from '_Store';
import { images } from 'utils';
import { Tabs } from 'components';

// :: screens
import Login from './pages/Auth/Login';
import Reset from './pages/Auth/Reset';
import Signup from './pages/Auth/Signup';
import Verify from './pages/Auth/Verify';

import Cart from './pages/Cart';
import Item from './pages/Item';
import Help from './pages/Help';
import User from './pages/User/_User';
import Home from './pages/Home';
import Stores from './pages/Stores';
import Order from './pages/User/Order';
import Search from './pages/Search';
import Review from './pages/User/Review';
import Orders from './pages/User/Orders';
import Webhook from './pages/Webhook';
import Category from './pages/Category';
import Location from './pages/Location';
import Favourites from './pages/User/Favourites';
import Wallet from './pages/User/Wallet';


const Rautes = () => {

    const { __data: { location } } = _store.useState();

    const routes = [
        // { path: '/welcome', component: Welcome, exact: true, auth: false, },
        { path: '/login', component: Login, exact: true, auth: false, },
        { path: '/reset', component: Reset, exact: true, auth: false, },
        { path: '/signup', component: Signup, exact: true, auth: false, },
        { path: '/verify', component: Verify, exact: true, auth: false, },

        { path: '/home', icon: images.Home, icon2: images.HomeRed, component: Stores, auth: true, tab: true, label: 'Home' },
        { path: '/store/:id', icon: images.Stores, icon2: images.StoresRed, component: Home, auth: true, },
        { path: '/cart', icon: images.Cart, icon2: images.CartRed, component: User, auth: true, tab: true, label: 'Cart' },
        { path: '/user', icon: images.Profile, icon2: images.ProfileRed, component: User, auth: true, tab: true, label: 'Profile', exact: true, },
        { path: '/help', icon: images.Help, icon2: images.HelpRed, component: User, auth: true, tab: true, label: 'Support' },

        { path: '/cart', component: Cart, auth: true, },
        { path: '/c/:slug.:id', component: Category, auth: true, },
        { path: '/i/:slug.:venue.:id', component: Item, auth: true, },
        { path: '/user/orders', component: Orders, auth: true, exact: true, },
        { path: '/user/orders/:id', component: Order, auth: true, exact: true, },
        { path: '/user/rate/:id', component: Review, auth: true, exact: true, },
        { path: '/user/favourites', component: Favourites, auth: true, exact: true, },
        { path: '/user/wallet', component: Wallet, auth: true, exact: true, },
        { path: '/help', component: Help, auth: true, exact: false, },
        { path: '/search', component: Search, auth: true, exact: true, },
        { path: '/location', component: Location, auth: true, exact: true, },
        { path: '/webhook/:id', component: Webhook, auth: true, exact: true, },
    ];
    // .filter(r => location.address.iso || ['/location'].includes(r.path));

    const prups = {};

    return (
        <React.Fragment>
            {/* <IonRouterOutlet mode="md"> */}
            {routes.filter(route => !route.tab || ['/home', '/user', '/stores'].includes(route.path)).map(route => (
                <Route key={route.path} path={route.path} exact={route.exact} render={p => (
                    <route.component {...p} {...prups} />
                )}
                />
            ))}
            <Route path="/" exact render={() => <Redirect to={location.address.iso ? '/home' : '/location'} />} />
            {/* </IonRouterOutlet> */}

            <Tabs tabs={routes.filter(route => route.tab)} />
        </React.Fragment>
    )
}

export default Rautes;