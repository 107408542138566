import React from 'react';
import { func, types } from 'utils';
import { _update } from '_Store';
import { RouteComponentProps } from 'react-router';


interface StateProps {
    item: types.Venue;
    layout: 'list' | 'grid';
};
interface Props extends RouteComponentProps, types.StateProps, StateProps { };
const Venue: React.FC<Props> = props => {
    const { item, layout, history } = props;

    const isOpen = func.isOpen(item);

    return (
        <React.Fragment>
            {layout === 'list' && (
                <div
                    id="Venue"
                    className="mb-4"
                    onClick={() => {
                        if (isOpen) {
                            func.setStorage('new_venue', 'yes');
                            _update.data('active_venue', item);
                            history.replace(`/store/${item.uuid}`);
                        }
                    }}
                >
                    <div className="bg-img pop" style={{ backgroundImage: `url("${item.cover_link}")`, borderRadius: 7, height: '15vh' }}>
                        {!isOpen && (
                            <div className="closed d-flex align-items-center justify-content-center fw-600">C L O S E D</div>
                        )}
                    </div>
                    <div className="mt-1 fw-500">{func.venueName(item)}</div>
                    <div className="text-muted small">{func.format.number(item.distance)}km away</div>
                </div>
            )}
        </React.Fragment>
    );
}

export default Venue;