import React from 'react';
import { IonContent, IonPage, useIonAlert, useIonToast } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { GoBack, ItemVariant, Loading, TabsOffset } from 'components';
import { axius, func, types, cartFx, images } from 'utils';
import { Button } from 'react-bootstrap';
import { _store, _update } from '_Store';

interface Params { id: string, slug: string, venue: string };
interface Props extends RouteComponentProps, types.StateProps { };
const Item: React.FC<Props> = props => {

    const [presentAlert] = useIonAlert();
    const [presentToast] = useIonToast();

    const path = props.history.location.pathname.split('/')[2]?.split('.') || {};
    const params: Params = { id: path[2], slug: path[0], venue: path[1] };

    const { __data: { cart, active_venue, location, cartDefault, promotions }, __auth: { user, isLoggedIn, favourites } } = _store.useState();

    const [v, setValues] = React.useState<types.Item | any>({ variants: {} });
    const [item, setItem] = React.useState<types.Item | any>({ preparation_time: '00:00:00', images: [], variants: [] });
    const [toAdd, setToAdd] = React.useState<types.Item | any>({});
    const [inCart, setInCart] = React.useState<types.Item | any>({});
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        return () => {
            if (!props.history.location.pathname.includes('/i/')) {
                setItem({ preparation_time: '00:00:00', images: [], variants: [] });
                setToAdd({});
                setInCart({});
                setLoading(false);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.history.location.pathname]);

    React.useEffect(() => {
        setLoading(true);
        axius.get(`venues/${params.venue}/items/${params.id}`).then((res: types.AxiusResponse) => {
            if (res.status === 200) {
                setItem(formatOptions(res.data));
                _update.data('item', formatOptions(res.data));
            }
            if (window?.gtag) {
                window?.gtag("event", "view_item", {
                    item_id: res.data.id,
                    item_name: res.data.name,
                    price: res.data.price,
                    discount: res.data.price_discount
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location.pathname]);

    React.useEffect(() => {
        if (item.uuid) {
            setToAdd({
                id: item.id,
                uuid: item.uuid,
                code: item.code,
                name: item.name,
                price: +(inCart.price || item.price_discount || item.price),
                images: item.images,
                images_links: item.images_links,
                price_total: Number(inCart.price_total || item.price_discount || item.price),
                quantity: item.quantity || 1,
                description: item.description,
                variants: inCart.variants || {},
                preparation_time: item.preparation_time,
                venue_categories: item.venue_categories,
            });
            setValues({ variants: inCart.variants || {}, });
            const isInCart = (cart.items || []).find((c: types.ItemCart) => c.uuid === item.uuid);
            if (!isInCart || (isInCart.uuid === inCart.uuid)) {
                setLoading(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item, inCart]);

    React.useEffect(() => {
        if (item.uuid) {
            setInCart((cart.items || []).find((c: types.ItemCart) => c.uuid === item.uuid) || {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    React.useEffect(() => {
        let price = toAdd.price_discount || toAdd.price;
        let price_total = price;
        for (let i = 0; i < Object.keys(v.variants).length; i++) {
            const op = Object.keys(v.variants)[i];
            for (let j = 0; j < (v.variants[op] || []).length; j++) {
                const iop = v.variants[op][j];
                if (iop.price > 0) {
                    price_total = price_total + (+iop.price * +iop.quantity);
                }
            }
        }
        setToAdd({
            ...toAdd,
            price,
            variants: v.variants,
            price_total,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [v]);

    React.useEffect(() => {
        if (inCart.uuid && toAdd.uuid) {
            let nCart = JSON.parse(JSON.stringify(cart));
            nCart['items'] = cartFx.add(toAdd, nCart.items);
            nCart['venue'] = active_venue;
            _update.data('cart', nCart);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toAdd]);

    const add2Cart = () => {
        let qty = 0;
        const min = item.variants.reduce((a: any, b: any) => (a + b.min), 0);
        for (let i = 0; i < Object.keys(v.variants).length; i++) {
            qty += v.variants[Object.keys(v.variants)[i]].reduce((a: any, b: any) => (a + b.quantity), 0);
        }
        // console.log(min, qty);
        // return;
        // item.variants.length !== Object.keys(v['variants']).length && 
        if (min > qty) {
            presentAlert({
                header: `Options`,
                message: `You must select at least ${item.variants.map((po: types.Variant) => { return `${po.max} ${po.name}`; }).join(', ')}`,
                buttons: ['Okay'],
            });
        } else {
            if (!cart.venue.uuid || active_venue.uuid === cart.venue.uuid) {
                toAdd['quantity'] = 1;
                let nCart = JSON.parse(JSON.stringify(cart));
                nCart['items'] = cartFx.add(toAdd, nCart.items);
                nCart['venue'] = active_venue;
                _update.data('cart', nCart);
                presentToast(`${item.name} added to cart!`, 1500);
                setInCart(toAdd);
                if (window?.gtag) {
                    window.gtag('event', 'add_to_cart', {
                        id: toAdd.id,
                        name: toAdd.name,
                        quantity: toAdd.quantity,
                        price: toAdd.price_total,
                        discount: toAdd.discount
                    })
                }
            } else {
                presentAlert({
                    header: `Sorry!`,
                    message: `You already have item(s) at "${func.venueName(cart.venue)}". You can not add this item from "${func.venueName(active_venue)}".`,
                    buttons: [
                        { text: 'Okay', },
                        {
                            text: 'Empty cart', handler: () => {
                                const nCart = { items: [] as types.Item[], venue: {}, ...cartDefault };
                                nCart['items'] = cartFx.add(toAdd, nCart.items);
                                nCart['venue'] = active_venue;
                                _update.data('cart', nCart);
                                presentToast(`${item.name} added to cart!`, 1500);
                                setInCart(toAdd);
                            }
                        }
                    ],
                });
            }
        }

    }

    const formatOptions = (row: any) => {
        if (row.uuid) {
            const option = ((row.variants[0] || { options: [] }).options[0] || { option: '' }).option;
            if (!option) {
                row['variants'] = row.variants.map((opt: any) => {
                    return {
                        name: opt.name,
                        options: opt.options.map((option: any) => {
                            return { option, price: 0 };
                        }),
                    };
                });
            }

            let variants = row.variants;
            if (availableSides.length > 0) {
                variants = row.variants.filter((opt: any) => (
                    ['flavour'].includes(opt.name.toLowerCase())
                    || opt.options.filter((option: any) => availableSides.includes(option.code)).length > 0
                )).map((opt: any) => {
                    return {
                        ...opt,
                        options: opt.options.filter((option: any) => (
                            availableSides.includes(option.code)
                            || ['flavour'].includes(opt.name.toLowerCase())
                        )),
                    }
                });
            }
            row['variants'] = variants;
            return row;
        }
        return [];
    }

    const add2Fav = () => {
        let favItems = JSON.parse(JSON.stringify(favourites)) as types.Item[];
        if (inFav.uuid) {
            favItems = favItems.filter(fav => fav.uuid !== item.uuid);
            _update.auth('favourites', favItems);
            axius.delte(`users/${user.uuid}/favourites/${item.uuid}`).then(res => {
                res.status === 200 && presentToast(`Item removed from your favourites`, 1500);
            });
        } else {
            favItems = favItems.concat(item);
            _update.auth('favourites', favItems);
            axius.post(`users/${user.uuid}/favourites`, { item: item.uuid }).then(res => {
                res.status === 200 && presentToast(`Item added to your favourites`, 1500);
            });
        }
    }

    const open = func.isOpen(active_venue);
    const inFav = favourites.find(fav => fav.uuid === item.uuid) || {} as types.Item;
    const itemImage = ((item.images[0] ? item.images_links[0] : (active_venue?.logo ? active_venue.logo_link : images.Logo)) || '');
    const availableSides = (active_venue.preferences.sides_available || []);
    const hasPricedVariants = (item.variants as types.Variant[]).filter(iv => iv.options.filter(ivo => ivo.price > 0).length > 0);
    const hasPromo = promotions.find(promo => promo.venues.includes(active_venue.uuid) && (promo.items.includes(item.code) || (item.venue_categories?.length > 0 && promo.categories.includes(item.venue_categories[0].uuid))));

    return (
        <IonPage id="Item">
            <IonContent fullscreen className="bg-img" style={{ backgroundImage: `url(assets/img/bg.pngs)`, backgroundAttachment: 'fixed', height: '100%', }}>
                {loading ? (
                    <Loading />
                ) : (
                    <div id="ItemScreen">
                        <div className="pt-3">
                            <div id="ItemTitle" className="px-3 p-3 d-flex align-items-center position-fixed" style={{ top: 0, right: 0, left: 0, zIndex: 999, background: '#fafafa' }}>
                                <GoBack
                                    {...props}
                                    title={
                                        `
                                        <h5 class="mb-0 fw-600 primary">${item.name}</h5>
                                        ${item.price_discount ? `
                                            <div>
                                                ₦${func.numberFormat(item.price_discount)}
                                                <s>&nbsp;₦${func.numberFormat(item.price)}&nbsp;</s>
                                            </div>
                                        ` : `<div>${hasPricedVariants ? 'From ' : ''}₦${func.numberFormat(item.price)}</div>`}
                                    `}
                                />
                            </div>

                            <div className="position-relative" style={{ top: document.getElementById('ItemTitle')?.offsetHeight }}>
                                <div className="d-flex justify-content-center px-3">
                                    <div
                                        className="bg-img animate__animated animate__fadeIn rounded"
                                        style={{
                                            height: '26vh', position: 'relative', width: '100vw',
                                            backgroundImage: `url("${itemImage}")`
                                        }}
                                    >
                                        {hasPromo && (
                                            <div className="item-discount">
                                                <i className="fa fa-gift primary" /> {hasPromo.title}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="px-3">
                                    {isLoggedIn && (
                                        <div className="text-right mt-3">
                                            <Button type="submit" variant="secondarys btn-md" onClick={add2Fav}>
                                                <div className="d-flex align-items-center text-uppercases">
                                                    {inFav.uuid ? (<img src={images.BookmarkFilled} alt="Bookmark" />) : (<img src={images.Bookmark} alt="Bookmark" />)} &nbsp; &nbsp;
                                                    <span>Add to Favourites</span>
                                                </div>
                                            </Button>
                                        </div>
                                    )}

                                    {item.description && (
                                        <div className="mt-2 mb-4 py-2" style={{ background: '#fff' }}>
                                            <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                        </div>
                                    )}

                                    {item.variants.map((io: types.Variant, i: number) => (
                                        <ItemVariant
                                            key={`${io.name}_${i}`} id={`${io.name}_${i}`} v={v} variant={io}
                                            onChange={variants => setValues((sv: any) => ({ ...sv, variants: { ...sv.variants, ...variants } }))}
                                        />
                                    ))}

                                    <p>&nbsp;</p>
                                    <div className="footers py-2s px-4s">
                                        {
                                            active_venue.preferences?.can_take_orders && open &&
                                            ((item.can_deliver && (item.venue_categories[0] || {}).delivery && location.option === 'delivery') || (location.option === 'pickup')) &&
                                            (
                                                <Button variant="secondary btn-block btn-lg text-uppercases" onClick={() => {
                                                    if (inCart.uuid) {
                                                        props.history.push(`/cart`);
                                                    } else {
                                                        add2Cart();
                                                    }
                                                }}>
                                                    <div className="d-flex justify-content-between">
                                                        <div>{inCart.uuid ? 'Checkout' : 'Add to cart'}</div>
                                                        <div><b>₦{func.numberFormat((inCart.uuid ? cartFx.total(cart.items) : +toAdd.price_total))}</b></div>
                                                    </div>
                                                </Button>
                                            )}
                                    </div>

                                    <div className="text-center my-4">
                                        <div className="primary fw-600 ins" onClick={() => props.history.push(`/`)}>
                                            Continue Shopping
                                        </div>
                                    </div>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <TabsOffset />
            </IonContent>
        </IonPage>
    );
};

export default Item;
