import React from 'react';
import { IonContent, IonPage, IonRefresher, IonRefresherContent } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { axius, func, types, images } from 'utils';
import { Header, TabsOffset } from 'components';
import { _store, _update } from '_Store';

import './_User.scss';

let refresher: any = null;

interface Props extends RouteComponentProps, types.StateProps { };
const Orders: React.FC<Props> = props => {
    const { __auth: { user }, __data: { orderStatus } } = _store.useState();

    const [step, setStep] = React.useState(0);
    const [data, setData] = React.useState<types.Order[]>([]);
    const [chunks, setChunks] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = () => {
        setLoading(true);
        axius.get('orders', { user: user.uuid }).then(res => {
            if (res.status === 200) {
                // .filter((ord: types.Order) => ord.status !== 'payment')
                setData(res.status === 200 ? res.data : []);
                const c = func.chunk(res.data, 6);
                setData(c[0] || []);
                setChunks(c || [[]]);
            }
            setLoading(false);
            refresher && refresher.detail.complete();
        });
    }

    const getNext = () => {
        setStep(step + 1);
        setData(data.concat(chunks[step + 1]));
    }

    return (
        <IonPage id="Orders">
            <Header {...props} showSearch={true} />

            <IonContent fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={(e) => {
                    refresher = e;
                    getData();
                }}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <div className="p-3 text-center text-white fw-600 title m-4">
                    <span className="secondary">YOUR</span> <span className="">ORDERS</span>
                </div>

                {loading && [1, 2, 3, 4, 5, 6].map(i => (
                    <div className="px-4" key={i}>
                        <div className="skeleton mb-1" style={{ height: 97.5, borderRadius: 7 }} />
                    </div>
                ))}

                {!loading && data.length === 0 && (
                    <div id="Loading" className="d-flex justify-content-center align-items-center" style={{ height: '60vh', }}>
                        <div className="text-center">
                            <img src={images.Logo} alt="Logo" height="120" style={{ opacity: .6 }} />
                            <div>We are waiting for your orders.</div>
                            <Button variant="secondary btn-blocks btn-lg btn-rounded mt-3" onClick={() => func.redirect('/')}>
                                &nbsp; &nbsp; Order Now &nbsp; &nbsp;
                            </Button>
                        </div>
                    </div>
                )}

                {!loading && data.length > 0 && (
                    <div className="px-4">
                        {data.map(row => {
                            const itemImage = (((row.items.find(ri => ri.images.length > 0) || { images_links: [] }).images_links || [])[0] || (row.venue.logo ? row.venue.logo_link : '') || images.Logo);
                            return (
                                <div key={row.uuid} id="Item" className={`animate__animated animate__fadeIn hover`} style={{ border: '0' }}>
                                    <div onClick={() => {
                                        _update.data('order', row);
                                        props.history.push(`/user/orders/${row.id}`);
                                    }}>
                                        <div className="pt-3s">
                                            <div className="divider d-flex pb-2 mb-2">
                                                <div className="bg-img" style={{ backgroundImage: `url("${itemImage}")`, width: 85, height: 85, borderRadius: 5, border: 0 }} />
                                                <div className="pl-3" style={{ width: 'calc(100vw - 85px)' }}>
                                                    <div className="mb-1">
                                                        <div className="black text-truncate fw-500" style={{ width: 'calc(100vw - 138px)' }}>{row.items[0].name}</div>
                                                        <div className="text-muted small">{moment(row.crdate).format('Do MMM YYYY • H.mm a')}</div>
                                                        {![types.OrderStatus.rejected, types.OrderStatus.completed, types.OrderStatus.timeout, types.OrderStatus.cancelled].includes(row.status) && (
                                                            <div className="text-muted small">₦{func.numberFormat(row.amount.total)}</div>
                                                        )}
                                                    </div>
                                                    <div className="my-2 small">{func.venueName(row.venue)}</div>
                                                    <div className={`fs-12 fw-600s d-flex small mt-2`}>
                                                        <div>#{row.id}</div>
                                                        <div className="mx-2">•</div>
                                                        <div className={`${orderStatus[row.status]?.c}`}>{row.status === types.OrderStatus.completed ? 'Delivered' : orderStatus[row.status]?.t}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}

                {!loading && (chunks.flat()).length > data.length && (
                    <div className="text-center pointer small mt-3" onClick={() => getNext()}>Load more</div>
                )}
                <p>&nbsp;</p>
                <TabsOffset />
            </IonContent>
        </IonPage>
    );
};

export default Orders;
