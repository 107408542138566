import React from 'react';
import { func, types } from 'utils';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Item } from 'components';
import { _store } from '_Store';
// import ItemsCarousel from 'react-items-carousel';

// const jQuery = (window as any).jQuery;

// const owlCarouselOptions = {
//     nav: false,
//     loop: false,
//     items: 2,
//     margin: 5,
//     stagePadding: 20,
// };

interface StateProps {
    id: string;
    data: types.Item[];
    title: string;
    loading: boolean;
    display?: string;
};
interface Props extends RouteComponentProps, types.StateProps, StateProps { };
const ItemsSlider: React.FC<Props> = props => {

    const { __data: { categories } } = _store.useState();

    const [show, setShow] = React.useState(true);
    const [data, setData] = React.useState<types.Item[]>([]);
    const [loading, setLoading] = React.useState(true);
    // const [activeItemIndex, setActiveItemIndex] = React.useState(0);

    React.useEffect(() => {
        // props.id.includes('burgers-sandwiches') && console.log(props.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        setShow(true);
        setData((props.data || []).filter((row) => (row.status === 'published')) || []);
        if (props.loading === undefined) {
            setLoading(false);
        } else {
            setLoading(props.loading);
        }
        // setTimeout(() => {
        //     jQuery(`#carousel-${props.id}`).owlCarousel(owlCarouselOptions);
        // }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const categoryId = !loading && !props.history?.location?.pathname?.includes('/v/') ? data.map((row: types.Item) => { return row.venue_categories; }).flat()[0] || {} : { uuid: '', name: '', id: 0 };
    const categoryId = !loading && !props.history?.location?.pathname?.includes('/v/') ? categories.find(ctg => ctg.uuid === props.id) : { uuid: '', name: '', id: 0 };


    return (
        <React.Fragment>
            {show && (
                <div id="ItemsSlider" className="p-4s">
                    <div className="pb-2 px-4 pt-4 d-flex justify-content-center justify-content-between">
                        <div className="fw-500 text-capitalize">{props.title}</div>
                        <div className="secondary pointer fw-500 d-flex align-items-center">
                            {categoryId?.uuid && (
                                <Link to={`/c/${func.seourl(categoryId?.name)}.${categoryId?.id}`} className="primary">View All <i className="fa fa-angle-right" /> </Link>
                            )}
                        </div>
                    </div>

                    {!loading && data.length === 0 && (
                        <div className="text-centers ml-4 small">
                            {props?.match?.path === '/outlet/:id' ? `No items found here` : 'No results found near you!'}
                        </div>
                    )}

                    {/* {loading && (
                        <div id={`carousel-${props.id}`} className="mb-2 owl-carousel car-loading">
                            {[1, 2, 3, 4, 5].map(i => (
                                <div key={i} className="skeleton" style={{ borderRadius: 7, height: 249 }} />
                            ))}
                        </div>
                    )} */}

                    {/* {!loading && (
                        <div id={`carousel-${props.id}`} className="mb-2 owl-carousel car-not-loading">
                            {data.length > 0 && data.map((item: types.Item) => (
                                <Item {...props} key={item.id} item={item} venue={null} layout="grid" />
                            ))}
                        </div>
                    )} */}

                    <div style={{ padding: `0 1.5rem` }}>
                        {loading ? (
                            <ItemsCarousel>
                                {[1, 2, 3, 4, 5].map(i => (
                                    <div key={i} className="mr-2">
                                        <div className="skeleton" style={{ borderRadius: 7, width: '40vw', height: 249, color: '#eee' }} />
                                    </div>
                                ))}
                            </ItemsCarousel>
                        ) : (
                            <ItemsCarousel>
                                {data.length > 0 && data.map((item: types.Item) => (
                                    <Item {...props} key={item.id} item={item} venue={null} layout="grid" />
                                ))}
                            </ItemsCarousel>
                        )}
                    </div>
                </div>
            )
            }
        </React.Fragment >
    );
}

export default ItemsSlider;


const ItemsCarousel = (props: any) => {

    return (
        <React.Fragment>
            <div id="ItemsCarousel" className="d-flex" style={{ overflow: 'scroll' }}>
                {props.children}
            </div>
        </React.Fragment>
    );
}