import React from "react";
import { IonContent, IonPage, useIonAlert } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { Form, Input } from "antd";
import { Button } from "react-bootstrap";
import { axius, func, images, types } from "utils";
import { IonLoading, PasswordStrength, PhoneNumber } from "components";
import { _store, _update } from "_Store";

interface Props extends RouteComponentProps, types.StateProps {}
const Signup: React.FC<Props> = (props) => {
	const {
		__data: { countries }
	} = _store.useState();

	const [form] = Form.useForm();
	const [presentAlert] = useIonAlert();

	const [pwd, setPassword] = React.useState("");
	const [loading, setLoading] = React.useState("");
	const [strongPWD, setStrongPWD] = React.useState(false);

	React.useEffect(() => {
		if (func.config.env === "local") {
			form.setFieldsValue({
				password: "space123",
				phone_number: "0262988710"
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submit = (v: any) => {
		let name = v.name.split(" ");
		v["last_name"] = v.name.replace(name[0], "").trim();
		v["first_name"] = name[0];
		v["phone_number"] = `+${
			countries.find((cnt: any) => cnt.code === (v.country || "ng"))?.calling_code
		}${v.phone_number.replace(/^0/, "")}`;
		if (strongPWD) {
			setLoading("authenticating...");
			axius.post("auth/signup", { ...v, type: "aarin" }).then((res: types.AxiusResponse) => {
				if (res.status === 200) {
					if (window?.gtag) {
						window?.gtag("event", "sign_up", {
							method: "username-password"
						});
					}
					_update.auth("user", res.data);
					_update.auth("token", "");
					func.init();
					props.history.push("/verify");
				} else {
					if (res.status === 412) {
						presentAlert(res.data.join("<br />"));
					} else {
						presentAlert(res.message);
					}
				}
				setLoading("");
			});
		} else {
			presentAlert(`Please select a strong password`);
		}
	};

	return (
		<IonPage id="Signup">
			<IonContent fullscreen={false}>
				<IonLoading open={loading ? true : false} message={loading} onCancel={() => setLoading("")} />

				<div className="p-4">
					<img className="mb-2" src={images.Logo} alt="Chicken Republic" style={{ width: 50 }} />
					<h2 className="mb-2">
						<b>Create An Account</b>
					</h2>
					<div className="text-muted">Let's get started.</div>
					<div>&nbsp;</div>

					<Form form={form} layout="vertical" onFinish={submit}>
						<Form.Item label="Full Name" name="name" rules={[{ required: true, message: "Field is required" }]}>
							<Input type="text" size="large" placeholder="Arinola Soyinka" />
						</Form.Item>
						<Form.Item label="Email Address" name="email" rules={[{ required: true, message: "Field is required" }]}>
							<Input type="email" size="large" placeholder="arinola@gmail.com" />
						</Form.Item>
						<PhoneNumber {...props} form={form} />
						<Form.Item
							label={
								<span>
									Password <small className="text-muted">(* Please select a strong password)</small>
								</span>
							}
							name="password"
							rules={[{ required: true, message: "Field is required" }]}
						>
							<Input.Password
								type="password"
								size="large"
								placeholder="• • • • • • • •"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</Form.Item>
						<PasswordStrength {...props} value={pwd} onChange={(e: boolean) => setStrongPWD(e)} />
						<div>&nbsp;</div>

						<Button type="submit" variant="secondary btn-block btn-md text-uppercases" style={{ height: 49 }}>
							Signup
						</Button>

						<div className="text-centers mt-2 text-muted small" onClick={() => props.history.push("/login")}>
							Already have an account? <b className="primary">Sign in</b>
						</div>
					</Form>
				</div>
				<div>&nbsp;</div>
				<div>&nbsp;</div>
				<div>&nbsp;</div>
			</IonContent>
		</IonPage>
	);
};

export default Signup;
