import React from 'react';
import { IonContent, IonPage, useIonAlert, useIonToast } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { Button } from 'react-bootstrap';
import { axius, func, types, images } from 'utils';
import { Header, Loading, TabsOffset } from 'components';
import { _store, _update } from '_Store';
import { Steps } from 'antd';
import moment from 'moment';
import * as _ from 'lodash';


interface Params { id: string };
interface Props extends RouteComponentProps, types.StateProps { };
const Order: React.FC<Props> = props => {

    const [presentAlert] = useIonAlert();
    const [presentToast] = useIonToast();

    const { __data: { cart, orderStatus, cartDefault }, } = _store.useState();

    const [ord, setOrder] = React.useState<types.Order>({} as types.Order);
    const [loading, setLoading] = React.useState(true);

    const path = props.history.location.pathname.split('/')[3]?.split('.') || {};
    const params: Params = { id: path[0], };

    React.useEffect(() => {
        const localOrder = func.getStorageJson('order');
        if (+localOrder.id === +params.id) {
            setOrder(localOrder);
            setLoading(false);
        } else {
            getOrder();
        }

        if (![types.OrderStatus.completed, types.OrderStatus.rejected, types.OrderStatus.cancelled, types.OrderStatus.rider_unavailable].includes(ord.status)) {
            const orderTimer = setInterval(() => {
                getOrder();
            }, 30 * 1000);
            return () => {
                clearInterval(orderTimer);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location.search]);

    const getOrder = () => {
        // setLoading(true);
        axius.get(`orders/${params.id}`).then((res: types.AxiusResponse) => {
            if (res.status === 200) {
                setOrder(res.data);
                _update.data('order', res.data);
            }
            setLoading(false);
        });
    }

    const reOrder = (item: types.Item) => {
        if (cart.items.length > 0) {
            presentAlert({
                header: '',
                message: `Your cart already contains some items. Proceeding with this reorder will clear the existing items. Want to Proceed?`,
                buttons: [
                    { text: 'Cancel', },
                    {
                        text: 'Yes, Proceed', handler: () => {
                            reOrderGo(item);
                        }
                    },
                ],
            });
        } else {
            reOrderGo(item);
        }
    }

    const reOrderGo = (item: types.Item) => {
        let newCard = {
            ...cartDefault,
            items: [item],
            venue: ord.venue || {},
        };
        _update.data('cart', newCard);
        setTimeout(() => {
            props.history.replace('/cart');
        }, 100);
    }

    const cancelOrder = () => {
        presentAlert({
            header: `Cancel Order`,
            message: `Cancel for a refund to your wallet. Proceed?`,
            buttons: [
                { text: 'Cancel' },
                {
                    text: 'Yes, Proceed', handler: () => {
                        setLoading(true);
                        axius.put(`orders/${ord.uuid}/cancellation`, { reason: 'Rider unavailable' }).then(res => {
                            if (res.status === 200) {
                                getOrder();
                                presentToast('Your order has been cancelled!', 1500);
                            } else {
                                setLoading(false);
                            }
                        });
                    }
                }
            ],
        });
    }

    const pickupOrder = () => {
        presentAlert({
            header: `Pickup Order`,
            message: `Pick up your order in-store. Proceed?`,
            buttons: [
                { text: 'Cancel' },
                {
                    text: 'Yes, Proceed', handler: () => {
                        setLoading(true);
                        axius.put(`orders/${ord.uuid}`, { status: 'pickup' }).then(res => {
                            if (res.status === 200) {
                                getOrder();
                                presentToast('Your order has been updated!', 1500);
                            } else {
                                setLoading(false);
                            }
                        });
                    }
                }
            ],
        });
    }

    const dates = _.invert(ord.status_updates);
    const stepItems = [
        {
            title: (
                <div className="d-flex justify-content-between">
                    <div>Looking for rider</div>
                    <div className="small text-muted">{dates.rider_pending ? moment(dates.rider_pending).format('H.mm A') : ''}</div>
                </div>
            ),
            type: ['delivery'],
            description: 'We are currently assigning riders for deliveries',
        },
        {
            title: (
                <div className="d-flex justify-content-between">
                    <div>Order Received</div>
                    <div className="small text-muted">{dates.new ? moment(dates.new).format('H.mm A') : ''}</div>
                </div>
            ),
            description: 'Waiting for store to confirm your order',
        },
        {
            title: (
                <div className="d-flex justify-content-between">
                    <div>Preparing Your Order</div>
                    <div className="small text-muted">{dates.preparing ? moment(dates.preparing).format('H.mm A') : ''}</div>
                </div>
            ),
            description: `Your meal will be redy in ${1 + Math.max(...(ord.items || []).map(item => +item.preparation_time.split(':')[1]))} minutes`,
        },
        {
            title: (
                <div className="d-flex justify-content-between">
                    <div>Your Order is Ready</div>
                    <div className="small text-muted">{dates.ready ? moment(dates.ready).format('H.mm A') : ''}</div>
                </div>
            ),
            description: `Your meal is ready for ${ord.type}`,
        },
        {
            title: (
                <div className="d-flex justify-content-between">
                    <div>En Route</div>
                    <div className="small text-muted">{dates.delivery ? moment(dates.delivery).format('H.mm A') : ''}</div>
                </div>
            ),
            type: ['delivery'],
            description: `Your meal is on it's way`,
        },
        {
            title: (
                <div className="d-flex justify-content-between">
                    <div>Order Arrived</div>
                    <div className="small text-muted">{dates.arrived ? moment(dates.arrived).format('H.mm A') : ''}</div>
                </div>
            ),
            type: ['delivery'],
            description: `Your rider is around to deliver your order`,
        },
        {
            title: (
                <div className="d-flex justify-content-between">
                    <div>Order {ord.type === 'pickup' ? 'Picked' : 'Delivered'}</div>
                    <div className="small text-muted">{dates.completed ? moment(dates.completed).format('H.mm A') : ''}</div>
                </div>
            ),
            description: `Your order has been delivered`,
        },
    ].filter(i => !i.type || i.type.includes(ord.type));

    const stepActive = {
        'pickup': [types.OrderStatus.new, types.OrderStatus.preparing, types.OrderStatus.ready, 'in-transit', types.OrderStatus.completed],
        'delivery': [types.OrderStatus.rider_pending, types.OrderStatus.new, types.OrderStatus.preparing, types.OrderStatus.ready, 'delivery', types.OrderStatus.arrived, types.OrderStatus.completed],
    };

    return (
        <IonPage id="Orders">
            <Header {...props} showSearch={false} />

            <IonContent fullscreen>
                {loading && <Loading style={{ height: '70vh' }} />}

                {!loading && ord.uuid && (
                    <div id="Order" className="contents c-mediumss p-4">
                        <div className="d-flex justify-content-between">
                            <h5 className="m-0s fw-600">Order #{ord.id}</h5>
                            <div className="small text-muted">{moment(ord.crdate).format('Do MMM YYYY • H.mm A')}</div>
                        </div>
                        {['accepted', types.OrderStatus.preparing, types.OrderStatus.ready, 'delivery', types.OrderStatus.arrived].includes(ord.status) && (
                            <div>Confirmation code: {ord.code}</div>
                        )}

                        {![types.OrderStatus.timeout, types.OrderStatus.rejected, types.OrderStatus.cancelled, types.OrderStatus.rider_unavailable, types.OrderStatus.unclaimed].includes(ord.status) ? (
                            <>
                                <div>&nbsp;</div>
                                <div className="divider" />
                                <div>&nbsp;</div>
                                <div>
                                    <Steps progressDot={true} size="small" current={stepActive[ord.type].indexOf(ord.status)} items={stepItems} />
                                </div>
                            </>
                        ) : (
                            <div className={`order-status mt-2 ${orderStatus[ord.status].c}`}>
                                {!ord.status?.includes('rider_') ? 'Order' : ''} {ord.status === types.OrderStatus.completed ? 'Delivered' : orderStatus[ord.status].t}{ord.status === types.OrderStatus.cancelled ? ': ' + ord.cancellation.reason : ''}
                            </div>
                        )}

                        {ord.status === types.OrderStatus.rider_unavailable && (
                            <div className="mt-4">
                                <div className="text-muted">We're really sorry! After waiting, we still couldn't find a rider for your order. You now have two options:</div>
                                <div className="d-flex justify-content-between mt-3">
                                    <Button variant="secondary" style={{ height: 38 }} onClick={pickupOrder}>
                                        &nbsp; &nbsp; &nbsp; Pickup &nbsp; &nbsp; &nbsp;
                                    </Button>
                                    <Button variant="primary" style={{ height: 38 }} onClick={cancelOrder}>
                                        &nbsp; &nbsp; &nbsp; Cancel &nbsp; &nbsp; &nbsp;
                                    </Button>
                                </div>
                            </div>
                        )}

                        <div>&nbsp;</div>
                        <div className="divider" />
                        <div>&nbsp;</div>

                        {ord.items.map((item: any) => (
                            <div key={item.uuid} className="d-flex pb-2 dividers mb-2">
                                <div className="" style={{ width: '3rem' }}>
                                    <div className="bg-img border" style={{ backgroundImage: `url("${(item.images_links || [])[0] || images.Logo}")`, height: '3rem', width: '3rem', borderRadius: 4 }} />
                                </div>
                                <div className="" style={{ marginLeft: '15px' }}>
                                    <span className="fw-600s">x{item.quantity}</span> {item.name}
                                    {Object.keys(item.variants).map((op) => (
                                        <div key={op}>
                                            {item.variants[op].map((po: any) => (
                                                <div key={po.option} className="small text-muted">
                                                    {po.quantity > 1 ? `${po.quantity} x ` : ''} {po.option} {po.price > 0 ? `(₦${func.numberFormat(+po.price * +po.quantity)})` : ''}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                    {item.discount > 0 ? (
                                        <>
                                            <div className="d-flex align-items-center">
                                                <div className="smalls mb-2 mr-2">₦{func.numberFormat(item.price_total - item.discount)}</div>
                                                <del className="smalls mb-2 text-muted">₦{func.numberFormat(item.price_total)}</del>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="fw-500">₦{func.numberFormat(item.price_total)}</div>
                                    )}
                                    {ord.status === types.OrderStatus.completed && (
                                        <div className="primary mt-2" onClick={() => reOrder(item)}>Re-order</div>
                                    )}
                                </div>
                                {/* <div className="col-12 divider my-3" /> */}
                            </div>
                        ))}

                        <div>&nbsp;</div>
                        <div className="divider" />
                        <div>&nbsp;</div>

                        <div className="text-uppercases mb-2"><b>Order Details</b></div>
                        <div className="row">
                            <div className="col-4">Type:</div>
                            <div className="col-8">{func.ucWords(ord.type)}</div>
                            {ord.type === 'delivery' && (
                                <>
                                    <div className="col-4 mt-2">Sub-Total:</div>
                                    <div className="col-8 mt-2">₦{func.numberFormat(ord.amount.amount)}</div>
                                    <div className="col-4 mt-2">Delivery Fee:</div>
                                    <div className="col-8 mt-2">₦{func.numberFormat(ord.amount.delivery)}</div>
                                </>
                            )}
                            {ord.amount.discount > 0 && (
                                <>
                                    <div className="col-4 mt-2">Discount:</div>
                                    <div className="col-8 mt-2">₦{func.numberFormat(ord.amount.discount)}</div>
                                </>
                            )}
                            <div className="col-4 mt-2">Total:</div>
                            <div className="col-8 mt-2">₦{func.numberFormat(ord.amount.total)}</div>
                        </div>

                        {ord.rider?.uuid && (
                            <div>
                                <div className="divider my-3" />
                                <div className="text-uppercases mb-2 mt-4"><b>Rider Details</b></div>
                                <div className="text-uppercases mt-2"><b>Name:</b> {ord.rider.name}</div>
                                <div className="text-uppercases mt-2"><b>Phone No.:</b> <a href={`tel:${ord.rider.phone_number}`}>{ord.rider.phone_number}</a></div>
                            </div>
                        )}

                        {ord.type === 'delivery' && (
                            <>
                                <div className="divider my-3" />
                                <div className="text-uppercases mb-2 mt-4"><b>Delivery Details</b></div>
                                <div className="text-uppercases mt-2"><b>To:</b></div>
                                <div className="primary">{ord.address.name}</div>
                                <div className="text-uppercases mt-2"><b>From:</b></div>
                                <div className="primary mb-4">{ord.venue.address.name}</div>
                                <div className="text-uppercases mt-2"><b>Instructions:</b></div>
                                <div className="primary mb-4">{ord.delivery.instructions || 'N/A'}</div>
                            </>
                        )}

                        {ord.status === types.OrderStatus.completed && (
                            <Button variant="secondary btn-block btn-lgs text-uppercases mt-4" style={{ height: 38 }} onClick={() => {
                                func.setStorageJson('order', ord);
                                props.history.push(`/user/rate/${ord.uuid}`);
                            }}>
                                {ord.review.id ? 'Your review' : 'Rate Service'}
                            </Button>
                        )}

                        <p>&nbsp;</p>
                        <span className="fw-600 primary-light" onClick={() => {
                            props.history.push(`/help/${ord.uuid}`);
                        }}>
                            <span className="ins">Need Help?</span> <img src={images.HelpRed} alt="Help" height={20} />
                        </span>
                    </div>
                )}
                <TabsOffset />
            </IonContent>
        </IonPage>
    );
};

export default Order;
