import React from 'react';
import queryString from 'query-string';
import { Form, InputGroup } from 'react-bootstrap';
import { BottomModal } from 'components';
import { func, images, mapFx, types } from 'utils';
import { RouteComponentProps, useParams } from 'react-router';
import { IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { _store, _update } from '_Store';


interface Params { id: string };
interface StateProps {
  showBack?: boolean;
  showSearch?: boolean;
  showSegments?: boolean;
  search?: {
    placeholder?: string;
    onSearch?: (input: string) => void;
  };

  goBack?: () => void;
};
interface Props extends RouteComponentProps, types.StateProps, StateProps { };
const Header: React.FC<Props> = (props) => {
  const params: Params = useParams();

  const { history, showSearch, showBack, search } = props;
  const { __data: { location }, } = _store.useState();

  const [goBack, showGoBack] = React.useState(false);
  const [changeLocation, setChangeLocation] = React.useState(false);

  let qs = queryString.parse(history.location.search);

  React.useEffect(() => {
    if (history.location.pathname !== '/' && location.address.name) {
      showGoBack(false);
    } else {
      showGoBack(!!props.showBack);
    }
    if ((!history.location.pathname.includes('promo') && !history.location.pathname.includes('coupon') && !history.location.pathname.includes('outlet')) && location.option === 'promos') {
      _update.data('location', { option: 'dine' });
    }
    if (!history.location.pathname.includes('/orders/') && !history.location.pathname.includes('/rate/')) {
      _update.data('order', {});
    }
    if (!history.location.pathname.includes('/i/')) {
      _update.data('item', {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  React.useEffect(() => {
    if (changeLocation) {
      const { maps } = (window as any).google;
      const Autocomplete = new maps.places.Autocomplete(document.getElementById('set_my_location_header'), {
        componentRestrictions: { country: func.config.env === 'live' ? 'ng' : null }
      });
      Autocomplete.addListener('place_changed', async () => {
        const plc = Autocomplete.getPlace();
        if (plc.geometry) {
          const lat = mapFx.addressComponents(plc);
          document.getElementById('set_my_location_header')?.setAttribute('value', '');
          _update.data('location', { address: { ...location.address, ...lat, zoom: 15 } });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLocation]);

  const getMyCurrentLocation = () => {
    mapFx.myCurrentLocation().then(address => {
      address && _update.data('location', { ...location, address: { ...address, } });
    });
  }

  return (
    <React.Fragment>
      <IonHeader id="Header" className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            &nbsp;&nbsp;
            {(showBack || !['/home', '/user', '/cart', '/help', '/location', '/stores'].includes(history.location.pathname)) && (
              <div className="pl-2" onClick={() => {
                if (props.goBack) {
                  props.goBack();
                } else {
                  history.goBack();
                }
              }}>
                {/* <IonBackButton defaultHref="/" icon={images.ArrowBack} /> */}
                <img src={images.ArrowLeftWhite} alt="ArrowLeftWhite" />
              </div>
            )}
          </IonButtons>
          <IonTitle>
            <a href="./"><img src={images.Logo} alt="Logo" height={70} /></a>
          </IonTitle>
          <IonButtons slot="end"></IonButtons>
        </IonToolbar>

        <div id="Location" className="text-white text-center pb-3 small">
          <div className={`d-flex justify-content-center text-white fs-12 ${!showSearch ? 'mb-3s' : ''}`}>
            <img src={images.Location} alt="Location" /> &nbsp;
            <div className="text-truncate" style={{ maxWidth: '70vw' }}>{location.address.name || 'N/A'}.</div> &nbsp;
            <span className="secondary text-underline pointer ins hides" onClick={() => setChangeLocation(true)}>Change</span>
          </div>
        </div>

        {showSearch && (
          <div id="HeaderSearch" className="p-3">
            <div className="d-flex">
              {goBack && (
                <div className="d-flex align-items-center pointer" style={{ width: '10vw' }} onClick={() => {
                  history.goBack();
                }}>
                  <img src={images.ArrowLeftWhite} alt="ArrowLeftWhite" />
                </div>
              )}
              <div style={{ width: goBack ? '90vw' : '100vw' }}>
                <Form.Group className="w-100">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text className="border-right-0">
                        <img src={images.Search} alt="Search" className="img-fluid" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      className="border-left-0" type="search" name="q" defaultValue={(qs.q || '') as string}
                      placeholder={search?.placeholder || 'Search meals'} required autoComplete="off"
                      onKeyUp={(e: any) => {
                        if (e?.key?.toLowerCase() === 'enter' || e.keyCode === 13) {
                          if (e.target.value) {
                            if (!search?.onSearch) {
                              history.push(`/search?q=${e.target.value}${history.location.pathname.includes('/c/') ? `&category=${params.id}` : ''}`);
                            } else {
                              search.onSearch(e.target.value);
                            }
                          }
                        }
                      }}
                      onChange={e => search?.onSearch && search.onSearch(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          </div>
        )}

        {changeLocation && (
          <BottomModal
            open={changeLocation}
            title="Change your location"
            onClose={() => {
              setChangeLocation(false);
            }}
          >
            <div className="p-4">
              <div className="fs-12 text-muted">Current location</div>
              <div className="fw-500s">
                <div className="mb-3 primary">
                  <img src={images.Location2} alt="Location2" className="img-fluids" height={17} /> {location.address.name}
                </div>

                <Form.Group className="w-100">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text className="border-right-0 bg-white">
                        <img src={images.Search} alt="Search" className="img-fluid" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control className="border-left-0" name="address" id="set_my_location_header"
                      // value={v.email}
                      // onChange={handleChange("email")}
                      placeholder="Enter new location"
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <div onClick={getMyCurrentLocation}>Use my current location</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
              </div>
            </div>
          </BottomModal>
        )}
      </IonHeader>
    </React.Fragment>
  );
};

export default Header;
