import React from 'react';
import { createRoot } from 'react-dom/client';
import HttpsRedirect from 'react-https-redirect';
import reportWebVitals from './reportWebVitals';
import { IonReactRouter } from '@ionic/react-router';
import { ErrorBoundary } from 'components';
import { datadogRum } from '@datadog/browser-rum';
import { ConfigProvider } from 'antd';
import { func } from 'utils';

import App from './App';

// 
import * as Sentry from '@sentry/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// func.config.env === 'live' && Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   release: `tomati-mobile-apk-${func.config.env}@${require('../package.json').version}`,
//   integrations: [
//     new BrowserTracing(),
//     new Sentry.Replay(),
//   ],
//   environment: func.config.env,
//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

!func.isLocal && process.env.REACT_APP_SENTRY_DSN && Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `cr-mobile-${process.env.REACT_APP_ENV}@${require('../package.json').version}`,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

!func.isLocal && datadogRum.init({
  applicationId: '57c0c0c2-3039-42b5-af24-6fd2812ccaac',
  clientToken: 'pub63d8c19d971da835bb2020174604137a',
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'us5.datadoghq.com',
  // service: 'mobile',
  service: (process.env.REACT_APP_ENV === 'staging' ? 'cr-mobile' : 'chr-mobile'),
  env: process.env.REACT_APP_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

const container = document.getElementById('root');
const root = createRoot(container!);

const antdTheme = {
  token: {
    colorPrimary: '#B6101D',
    colorSecondary: '#FFCC00',
    borderRadius: 6,
    fontFamily: '"Gotham", sans-serif',
  },
};

root.render(
  <React.Fragment>
    <HttpsRedirect>
      <div className={`${!func.isMobile ? 'mobile-frame' : ''}`}>
        <IonReactRouter>
          <ErrorBoundary>
            <ConfigProvider theme={antdTheme}>
              <App />
            </ConfigProvider>
          </ErrorBoundary>
        </IonReactRouter>
      </div>
    </HttpsRedirect>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// custom service worker
// if (!func.isLocal && 'serviceWorker' in navigator) {
//   navigator.serviceWorker.register(`sw.js?${Date.now()}`)
//     .then(registration => {
//       console.log('Service Worker registered with scope:', registration.scope);
//     })
//     .catch(error => {
//       console.error('Service Worker registration failed:', error);
//     });
// }
