import React from 'react';
import { RouteComponentProps } from 'react-router';
import { func, types, images, cartFx } from 'utils';
import { Button } from 'react-bootstrap';
import { CustomRadio, RepDashTnC } from 'components';
import { Checkbox, Input } from 'antd';
import { _store, _update } from '_Store';

interface StateProps {
    minCartValue: boolean;

    checkout: (deliveryFee: number, accepted?: boolean) => void;
}
interface Props extends RouteComponentProps, types.StateProps, StateProps { };
const Checkout: React.FC<Props> = props => {

    const { checkout, minCartValue } = props;
    const { __data, __data: { cart, location, active_venue, delivery_fees }, __auth: { user } } = _store.useState();

    const [tnc, setTnc] = React.useState({ accepted: false, modal: false });

    const deliveryFee = +cart.deliveryFee;
    const discount = cart.items.reduce((a, b) => (a + (b.discount || 0)), 0);
    const total = cartFx.total(cart.items, true, true) + (deliveryFee || 0);
    let paymentOptions = __data.paymentOptions;
    if (+user.wallet > 0) {
        paymentOptions = [...paymentOptions, { code: 'wallet', label: `Wallet: ₦${func.numberFormat(user.wallet)}`, disabled: +user.wallet < total }];
    }

    return (
        <React.Fragment>
            <div id="Checkout" className="p-4 content c-small">
                {location.option === 'delivery' && (
                    <>
                        <div className="primarys fw-600 mb-2">Deliver To:</div>
                        <div className="p-3 alert alert-warning">
                            <div className="primarys d-flex align-items-center">
                                <img src={images.Location2} alt="Location" height={15} />
                                <div className="ml-2">{location.address.name}</div>
                            </div>
                        </div>
                    </>
                )}

                {location.option === 'pickup' && (
                    <>
                        <div className="primarys fw-600 mb-2">Pickup From:</div>
                        <div className="p-3 alert alert-warning">
                            <div className="primarys d-flex align-items-center">
                                <img src={images.Location2} alt="Location" height={15} />
                                <div className="ml-2">{active_venue.address.name}</div>
                            </div>
                        </div>
                    </>
                )}

                {location.option === 'delivery' && delivery_fees?.length > 0 && (
                    <div>
                        <div className="pb-4s mt-4">
                            <div className="primarys fw-600 mb-2 mt-4">Delivery Partners:</div>
                            <div className="side py-2">
                                {delivery_fees.map((df, i) => (
                                    <div key={`${i}_${df.partner}`} className="mx-3 my-2">
                                        <CustomRadio
                                            key={`${i}_${df.partner}`}
                                            label={(
                                                <div className="my-2">
                                                    <div className="d-flex align-items-center justify-content-between fw-600">
                                                        <div>{df.partner === 'cr' ? 'RepDash' : func.ucWords(df.partner)}</div>
                                                        <div>₦{func.numberFormat(df.amount)}</div>
                                                    </div>
                                                    {df.partner === 'cr' && (
                                                        <div className="text-muted small mt-2 italic">
                                                            <Checkbox checked={tnc.accepted} onChange={e => setTnc(st => ({ ...st, accepted: e.target.checked }))} />&nbsp;
                                                            <span onClick={() => setTnc(st => ({ ...st, modal: true }))}>30min delivery promise T&Cs Opt in.</span>
                                                        </div>
                                                    )}
                                                    {df.partner !== 'cr' && ['CR116', 'CR133', 'PX254', 'PX297'].includes(active_venue.uuid) && (
                                                        <div className="text-muted small mt-2 italic"><i className="fa fa-exclamation-circle text-xs warning" /> 30min delivery promise does not apply.</div>
                                                    )}
                                                </div>
                                            )}
                                            selected={cart.delivery.channel === df.partner}
                                            className="primarys"
                                            onClick={() => {
                                                const copyCart: types.Cart = func.copyObject(cart);
                                                copyCart['deliveryFee'] = df.amount;
                                                copyCart['delivery']['channel'] = df.partner;
                                                _update.data('cart', { ...copyCart });
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="dividers" />
                    </div>
                )}

                {cart.venue?.payment_options?.length > 0 && (
                    <div>
                        <div className="pb-4 mt-4">
                            <div className="primarys fw-600 mb-2 mt-4">Payment Options:</div>
                            <div className="side py-2">
                                {paymentOptions.filter(po => cart.venue?.payment_options.includes(po.code)).map((po: any) => (
                                    <div key={po.code} className="mx-3 my-3">
                                        <CustomRadio
                                            key={po.code}
                                            label={po.label.replace('delivery', location.option)}
                                            selected={cart.payType === po.code ? true : false}
                                            disabled={po.disabled}
                                            className="primarys"
                                            onClick={() => {
                                                _update.data('cart', { ...cart, payType: po.code });
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="dividers" />
                    </div>
                )}

                <div>
                    <div className="primarys d-flex justify-content-between fw-600s mb-2 mt-4">
                        <div>Sub-Total:</div>
                        <div className="">
                            ₦{func.numberFormat(cartFx.total(cart.items))}
                        </div>
                    </div>
                    <div className="divider my-3 hide" />

                    {location.option === 'delivery' && (
                        <div>
                            <div className="primarys d-flex justify-content-between mb-2">
                                <div>Delivery Fee:</div>
                                <div className="">₦{func.numberFormat(deliveryFee)}</div>
                            </div>
                            <div className="divider my-3 hide" />
                        </div>
                    )}

                    {discount > 0 && (
                        <div>
                            <div className="primarys d-flex justify-content-between mb-2">
                                <div>Discount:</div>
                                <div className="">₦{func.numberFormat(discount)}</div>
                            </div>
                            <div className="divider my-3 hide" />
                        </div>
                    )}

                    <div className="primarys d-flex justify-content-between my-4- fw-600">
                        <div>Total:</div>
                        <div className="fs-20 primary">₦{func.numberFormat(total)}</div>
                    </div>
                </div>

                {location.option === 'delivery' && (
                    <>
                        <div className="primarys fw-600 mb-2 mt-4">Delivery Instructions:</div>
                        <Input.TextArea
                            size="large"
                            rows={3} value={cart.delivery.instructions}
                            placeholder="Let the delivery person know if you have any special requests"
                            onChange={e => {
                                _update.data('cart', { ...cart, delivery: { ...cart.delivery, instructions: e.target.value, } });
                            }}
                        />
                        <div className="divider my-3" />
                    </>
                )}

                <div className="footers pb-3 mt-5">
                    <Button variant={`${minCartValue ? 'secondary' : 'gray'} btn-block btn-lg text-uppercases`} disabled={!!(location.option === 'delivery' && !deliveryFee)} onClick={() => checkout(deliveryFee, tnc.accepted)}>
                        <div className="d-flex justify-content-between">
                            <div>Place Order</div>
                            <div><b>₦{func.numberFormat(cartFx.total(cart.items, true, true) + (deliveryFee || 0))}</b></div>
                        </div>
                    </Button>
                </div>

                <div className="text-center my-2">
                    <span className="primarys fw-600 ins" onClick={() => props.history.push(`/`)}>
                        Continue Shopping
                    </span>
                </div>
            </div>

            <RepDashTnC
                open={tnc.modal}
                onAccept={() => setTnc(st => ({ ...st, accepted: true }))}
                onCancel={() => setTnc(st => ({ ...st, modal: false }))}
            />
        </React.Fragment>
    );
};

export default Checkout;
