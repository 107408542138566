import { Store } from 'pullstate';
import { func, types } from 'utils';

const user = func.getStorageJson('user') || {};
const token = func.getStorageJson('token') || '';
const active_venue: types.Venue = func.getStorageJson('active_venue') || { uuid: '', delivery: { schedule: {} } };

const filterDefault = {
    tags: [],
    min_price: '',
    max_price: '',
    drinks: [],
    venues: [],
    cuisines: [],
    categories: [],
}
const cartDefault = {
    items: [],
    venue: {},
    payType: 'advance',
    delivery: { channel: '', instructions: '', },
    quantity: 0,
    deliveryFee: 0,
}

const initialState = {
    __auth: {
        user,
        token,
        isLoggedIn: user && user.uuid,
        favourites: (func.getStorageJson('favourites') || []) as types.Item[],
    },
    __data: {
        home: {},
        phones: {
            // br: { code: '+55', phone_length: 12 },
            ng: { code: '+234', phone_length: 11 },
            gh: { code: '+233', phone_length: 10 },
            // ke: { code: '+254', phone_length: 10 },
            // ie: { code: '+353', phone_length: 10 },
            // ci: { code: '+225', phone_length: 9 },
            // ae: { code: '+971', phone_length: 10 },
        },
        cartDefault,
        cart: func.getStorageJson('cart') || cartDefault,
        item: {} as types.Item,
        order: func.getStorageJson('order') || {},
        options: [
            { key: 'pickup', label: 'Pick Up', },
            { key: 'delivery', label: 'Delivery', },
        ],
        filter: (func.getStorageJson('filter') || filterDefault) as typeof filterDefault,
        countries: func.getStorageJson('countries') || [],
        categories: func.getStorageJson('categories') || [],
        delivery_fees: func.getStorageJson('delivery_fees') || [],
        delivery_partners: func.getStorageJson('delivery_partners') || [],
        filterDefault,
        active_venue,
        location: func.getStorageJson('location') || {
            option: 'delivery',
            radius: 30,
            address: {},
        },
        paymentOptions: [
            { code: 'advance', label: 'Bank Transfer / Visa / MasterCard' },
        ],
        orderStatus: {
            [types.OrderStatus.new]: { c: `warning`, t: `Processing` },
            [types.OrderStatus.ready]: { c: `warning`, t: `Ready` },
            [types.OrderStatus.timeout]: { c: `danger`, t: `Cancelled` },
            [types.OrderStatus.payment]: { c: `warning`, t: `Pending` },
            [types.OrderStatus.rejected]: { c: `danger`, t: `Cancelled` },
            [types.OrderStatus.accepted]: { c: `warning`, t: `Preparing` },
            [types.OrderStatus.preparing]: { c: `warning`, t: `Preparing` },
            [types.OrderStatus.delivery]: { c: `warning`, t: `In Transit` },
            [types.OrderStatus.arrived]: { c: `warning`, t: `Arrived` },
            [types.OrderStatus.completed]: { c: `success`, t: `Completed` },
            [types.OrderStatus.cancelled]: { c: `danger`, t: `Cancelled` },
            [types.OrderStatus.unclaimed]: { c: `danger`, t: `Unclaimed` },
            [types.OrderStatus.rider_pending]: { c: 'warning', t: `Looking for rider` },
            [types.OrderStatus.rider_unavailable]: { c: `warning`, t: `We couldn't find a rider` },
        } as Record<types.OrderStatus, { c: string; t: string; }>,
        promo_cart: {
            items: [],
        },
        promotions: func.getStorageJson('promotions') || [],
        venues: func.getStorageJson('venues') || [],
        cache: {
            user: '',
        },
    },
};

if (['live', 'staging', 'local'].includes(func.config.env)) {
    // initialState.__data.paymentOptions = initialState.__data.paymentOptions.concat({ code: 'delivery', label: 'Payment on delivery' });
}


export const _store = new Store<types.StoreProps>(initialState);

export const _update = {
    auth: (key: keyof typeof initialState.__auth, data: any) => {
        _store.update(s => {
            // @ts-ignore
            s.__auth[key] = data;
            s.__auth['isLoggedIn'] = !!(s.__auth.user && s.__auth.user.uuid && s.__auth.token);
        });
        func.setStorageJson(key, data);
    },
    data: (key: keyof typeof initialState.__data, data: any) => {
        _store.update(s => {
            // key === 'cart' && console.log(data);
            if (!['countries', 'categories', 'delivery_partners', 'promotions', 'delivery_fees', 'venues'].includes(key)) {
                data = { ...s.__data[key], ...data, };
            } else if (JSON.stringify(data) === '{}') {
                data = {};
            }
            s.__data[key] = data;
        });
        !['promotions'].includes('key') && func.setStorageJson(key, data);
    },
}