import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { Header, TabsOffset, Venue } from 'components';
import { func, types } from 'utils';
import { _store } from '_Store';

interface Props extends RouteComponentProps, types.StateProps { };
const Stores: React.FC<Props> = props => {

    const { __data: { venues } } = _store.useState();

    const [q, setSearch] = React.useState('');

    const filteredVenues = q ? venues.filter(v => func.venueName(v).trim().toLowerCase().includes(q)) : venues;

    return (
        <IonPage id="Stores">
            <Header
                {...props} showSearch={true} showSegments={false}
                search={{
                    placeholder: 'Search restaurants',
                    onSearch: (input) => setSearch(input),
                }}
            />
            <IonContent fullscreen className="bg-img" style={{ backgroundAttachment: 'fixed', height: '100%' }}>
                <div className="p-4">
                    <div className="fw-500 text-capitalize- mb-4" style={{ fontSize: 17 }}>All restaurants near you</div>

                    {filteredVenues.map(venue => (
                        <Venue {...props} key={venue.uuid} item={venue} layout="list" />
                    ))}
                </div>
                <TabsOffset />
            </IonContent>
        </IonPage>
    );
};

export default Stores;
