import React from 'react';
import { Modal } from 'antd';
import { Button } from 'react-bootstrap';

interface Props {
    open: boolean;

    onAccept: () => void;
    onCancel: () => void;
};
const RepDashTnC = ({ open, onAccept, onCancel }: Props) => {

    return (
        <React.Fragment>
            <Modal open={open} centered onCancel={onCancel} width="85vh" closable footer={null}>
                <div className="courier">
                    <div className="my-4" />
                    <p>By placing an order under the LETS BET Your order delivered in 30-Minutes Promise, you agree to the following terms and conditions:</p>
                    <ul style={{ listStyle: 'none', paddingLeft: 10 }}>
                        <li style={{ marginBottom: 10 }}>
                            <b>1. Promotional Availability</b>
                            <div>This offer is only valid between 9:00 AM and 4:30 PM daily.</div>
                            <div>Orders placed outside this window do not qualify for the promotion.</div>
                        </li>
                        <li style={{ marginBottom: 10 }}>
                            <b>2. Customer Contact & Verification</b>
                            <div>A verified phone number is required for all qualifying orders.</div>
                            <div>Customers must provide a reachable phone number that has been verified via OTP.</div>
                        </li>
                        <li style={{ marginBottom: 10 }}>
                            <b>3. Customer Availability & Order Collection</b>
                            <div>Customers must be on hand to receive their order as soon as the rider arrives.</div>
                            <div>A push notification (via email and SMS) will be sent when the rider is near. The rider may also call, though this is optional.</div>
                            <div>If the customer is unavailable, they must ensure that someone is present with the confirmation PIN to collect the order.</div>
                        </li>
                        <li style={{ marginBottom: 10 }}>
                            <b>4. Delivery Time & Waiting Period</b>
                            <div>If the order arrives within the 30-minute window, the rider will wait for a maximum of 5 minutes.</div>
                            <div>If the customer (or a representative) fails to collect the order within the 5-minute window, the rider will return the food to the store.</div>
                            <div>The order will then be converted to a pick-up order, and the customer must collect it before the store closes at 5:00 PM on the same day.</div>
                            <div>If the order is not collected by closing time, the meal will be donated to a charity of Chicken Republic’s choice.</div>
                        </li>
                        <li style={{ marginBottom: 10 }}>
                            <b>5. Refund Policy for Late Deliveries</b>
                            <div>If Chicken Republic fails to deliver within 30 minutes, the full value of the meal will be refunded to the customer’s wallet.</div>
                            <div>Wallet funds can only be used for future purchases on the platform and cannot be withdrawn as cash.</div>
                        </li>
                        <li style={{ marginBottom: 10 }}>
                            <b>6. Order Restrictions</b>
                            <div>The 30-Minute Delivery Promise does not apply to the entire menu. Chicken Republic reserves the right to limit eligible menu items.</div>
                            <div>Orders qualifying for this promotion are capped at ₦20,000 per order, ensuring feasibility for a single rider to deliver within the timeframe.</div>
                        </li>
                        <li style={{ marginBottom: 10 }}>
                            <b>7. Dispute Resolution</b>
                            <div>If you experience any issues with your order, you can contact us at:</div>
                            <div>📞 Customer Support Line: [Insert Contact Number]</div>
                            <div>📧 Email: [Insert Support Email]</div>
                        </li>
                        <li>
                            <b>8. General Terms</b>
                            <div>Chicken Republic reserves the right to modify, suspend, or terminate this promotion at any time.</div>
                            <div>This offer is available only in select locations and may be subject to rider availability.</div>
                            <div>Any form of misuse or attempt to exploit this promotion may result in the cancellation of the order and/or account suspension.</div>
                            <div> By placing an order, you acknowledge that you have read and agreed to these Terms & Conditions</div>
                        </li>
                    </ul>
                    <div className="my-4" />
                    <Button block onClick={() => { onAccept(); onCancel(); }}>Accept</Button>
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default RepDashTnC;