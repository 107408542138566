export interface StateProps {
    // // state
    // _auth: {
    //     user: User;
    //     token: string;
    //     isLoggedIn: boolean;
    // };
    // _data: {
    //     home: any;
    //     phones: any;
    //     cart: Cart;
    //     item: Item;
    //     order: Order;
    //     options: {
    //         key: string;
    //         label: string;
    //     }[];
    //     filter: any;
    //     countries: Country[];
    //     categories: Category[];
    //     filterDefault: any;
    //     active_venue: Venue;
    //     location: {
    //         address: Address;
    //         option: 'delivery' | 'pickup' | 'promos' | 'dine';
    //         radius: number;
    //     };
    //     paymentOptions: any;
    //     orderStatus: any;
    //     promo_cart: any;
    //     cartDefault: any;
    //     delivery_partners: any;
    //     favourites: Item[];
    // };
}

export interface StoreProps {
    __auth: {
        user: User;
        token: string;
        isLoggedIn: boolean;
        favourites: Item[];
    };
    __data: {
        home: any;
        phones: any;
        cart: Cart;
        item: Item;
        order: Order;
        options: {
            key: string;
            label: string;
        }[];
        filter: any;
        countries: Country[];
        categories: Category[];
        filterDefault: any;
        active_venue: Venue;
        location: {
            address: Address;
            option: 'delivery' | 'pickup' | 'promos' | 'dine';
            radius: number;
        };
        paymentOptions: {
            code: string;
            label: string;
            disabled?: boolean;
        }[];
        orderStatus: Record<OrderStatus, { c: string; t: string; }>;
        promo_cart: any;
        cartDefault: any;
        delivery_fees: {
            rating: number;
            amount: number;
            partner: 'cr' | 'glovo' | 'chowdeck';
        }[];
        delivery_partners: any;
        promotions: Promotion[];
        venues: Venue[];
        cache: {
            user: string;
        };
    };
}

export interface AxiusResponse {
    count: number;
    data: any;
    token: string;
    status: number;
    message: string;
    pay: any;
}

export interface OrdersFilter {
    status?: number;
}

export interface Category {
    id?: number;
    uuid: string;
    name: string;
    crdate: string;
    sequence: number;
    delivery: number;
    update: string;
    venue: string;
    price: number;
}

export interface Country {
    calling_code: string;
    code: string;
    currency: string;
    decimal: number;
    name: string;
    phone_length: number;
}

export interface ItemDefault {
    can_deliver: number;
    crdate: string;
    description: string;
    distance: number;
    free_sides_max: number;
    id: number;
    images: string[];
    images_links: string[];
    name: string;
    preparation_time: string;
    price: number;
    price_discount: number;
    status: string;
    update: string;
    uuid: string;
    venue: any;
    venue_categories: Category[];
    drinks: [];
    free_sides: [];
    paid_sides: [];
    quantity: number;
    price_total: number;
    code: string;
    promotion: string;
    discount: number;
}

export type Item = ItemDefault & {
    variants: Variant[];
}

export type ItemCart = ItemDefault & {
    variants: Record<string, Variant[]>;
}

export interface Variant {
    min: number;
    max: number;
    name: string;
    options: {
        code: string;
        price: number;
        option: string;
        quantity?: number;
    }[];
    price: number;
    quantity: number;
}


export interface Venue {
    address: Address;
    bank: [];
    country: Country;
    cover: string;
    cover_link: string;
    crdate: string;
    delivery: {
        cart_minimum: number;
        fee: number;
        fee_type: string;
        options: string[];
        radius: number;
        schedule: {
            to: string;
            from: string;
        }
    };
    description: string;
    distance: number;
    email: string;
    id: number;
    logo: string;
    logo_link: string;
    name: string;
    payment_options: string;
    phone_number: string;
    preferences: Preferences;
    qrcode: string;
    qrcode_link: string;
    schedule: Schedule[];
    slug: string;
    status: string;
    update: string;
    uuid: string;
    venue: string;
}

export interface Schedule {
    available: boolean;
    closing: string;
    day: string;
    opening: string;
}

export interface Address {
    iso: string;
    lat: number;
    latitude: number;
    lng: number;
    longitude: string;
    name: string;
    state: string;
    zoom: number;
    address: string;
    time_zone: string;
    city: string;
}

export interface Order {
    address: Address;
    amount: {
        total: number;
        amount: number;
        discount: number;
        delivery: number;
    };
    cancellation: {
        reason: string;
    };
    code: string;
    crdate: any;
    delivery: {
        channel: string;
        instructions: string;
    };
    id: number;
    items: Item[];
    origin: string;
    paytoken: string;
    paytype: string;
    review: {
        id: number;
        comment: string;
        delivery_channel: string;
        delivery_rate: number;
        meal_rate: number;
    };
    status: OrderStatus;
    status_updates: any;
    type: 'delivery' | 'pickup';
    update: string;
    user: User;
    rider: User;
    uuid: string;
    venue: Venue;
}

export enum OrderStatus {
    new = 'new',
    accepted = 'accepted',
    preparing = 'preparing',
    completed = 'completed',
    rejected = 'rejected',
    cancelled = 'cancelled',
    timeout = 'timeout',
    delivery = 'delivery',
    payment = 'payment',
    ready = 'ready',
    arrived = 'arrived',
    pickup = 'pickup',
    unclaimed = 'unclaimed',
    rider_pending = 'rider_pending',
    rider_unavailable = 'rider_unavailable',
}

export interface User {
    address: Address;
    avatar: string;
    avatar_link: string;
    birthdate: string;
    country: Country;
    crdate: string;
    email: string;
    first_name: string;
    gender: string;
    id: number;
    last_name: string;
    name: any;
    phone_number: string;
    preferences: Preferences;
    push_ids: string;
    role: any;
    status: string;
    subscription: any;
    type: string;
    update: string;
    uuid: string;
    wallet: number;
}

export interface Cart {
    delivery: {
        code?: string;
        channel: string;
        instructions?: string;
    }
    deliveryFee: number;
    items: ItemCart[];
    payType: string;
    venue: Venue;
}

export interface Preferences {
    online: boolean;
    offline_for: number;
    offline_at: string;
    items_available: string[];
    sides_available: string[];
    can_take_orders: boolean;
}

export interface Promotion {
    id: number;
    uuid: string;
    title: string;
    description: string;
    type: PromotionTypes;
    value: number;
    date: {
        start: string;
        end: string;
    };
    limit: {
        minimum_order: number;
        maximum: number;
        frequency: string;
    };
    venues: string[];
    items: string[];
    categories: string[];
    providers: string[];
    reference: Record<string, string>;
    crdate: string;
    update: string;
    is_active: boolean;
}

export enum PromotionTypes {
    'BOGOF' = 'BOGOF',
    'CASHBACK' = 'CASHBACK',
    'DELIVERY' = 'DELIVERY',
    'FLAT_OFF_ALL' = 'FLAT_OFF_ALL',
    'FLAT_OFF_ITEM' = 'FLAT_OFF_ITEM',
    'FLAT_OFF_CATEGORY' = 'FLAT_OFF_CATEGORY',
    'PERCENTAGE_OFF_ALL' = 'PERCENTAGE_OFF_ALL',
    'PERCENTAGE_OFF_ITEM' = 'PERCENTAGE_OFF_ITEM',
    'PERCENTAGE_OFF_CATEGORY' = 'PERCENTAGE_OFF_CATEGORY',
}

export interface Payment {
    id: number;
    uuid: string;
    user: User;
    token: string;
    amount: number;
    type: string;
    status: 0 | 1 | 2;
    description: string;
    gateway: string;
    country: Country;
    data: any;
    json_init: any;
    json_back: any;
    crdate: string;
    update: string;
    operation: '-' | '+';
    order?: Pick<Order, 'id'>;
}