import React from 'react';
import { IonContent, IonPage, useIonAlert } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { Form, Input } from 'antd';
import { Button } from 'react-bootstrap';
import { axius, func, images, types } from 'utils';
import { IonLoading, PhoneNumber } from 'components';
import { _store, _update } from '_Store';


interface Props extends RouteComponentProps, types.StateProps { };
const Login: React.FC<Props> = props => {

    const { __data: { countries, location, cart } } = _store.useState();

    const [form] = Form.useForm();

    const [vv, setValues] = React.useState({ type: 'aarin', code: '', username: '', password: '' });
    const [code, setCode] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState('');

    const [presentAlert] = useIonAlert();

    React.useEffect(() => {
        if (func.config.env === 'local') {
            form.setFieldsValue({
                country: 'gh',
                password: 'space123',
                phone_number: '0262988710',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resendCountdown = () => {
        setCount(0);
        let timer = setInterval(() => {
            setCount(caunt => caunt + 1);
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }

    const submit = (v: any) => {
        if (v.code) {
            submitCode(v);
        } else {
            submitLogin(v);
        }
    }

    const submitLogin = (v: any) => {
        setLoading('authenticating...');
        v['username'] = vv.username || `+${countries.find(loc => loc.code === v.country)?.calling_code}${v.phone_number.replace(/^0/, '')}`;
        axius.post('auth', { ...vv, ...v }).then((res: types.AxiusResponse) => {
            if (res.status === 200) {
                setCode(true);
                setValues(sv => ({ ...sv, ...v }));
                resendCountdown();
            } else {
                if (res.status === 412) {
                    presentAlert(res.data.join('<br />'));
                } else {
                    presentAlert(res.message);
                }
            }
            setLoading('');
        });
    }

    const submitCode = (v: any) => {
        setLoading('authenticating...');
        axius.post('auth/token', { ...vv, ...v }).then((res: types.AxiusResponse) => {
            if (res.status === 200) {
                _update.auth('user', res.data);
                _update.auth('token', res.token);
                func.init();
                setTimeout(() => {
                    if (location.address.lat) {
                        props.history.replace(cart.items.length > 0 ? '/cart' : '/home');
                        // window.location.reload();
                    } else {
                        func.setStorage('after_login', 'yes');
                        props.history.push('/location');
                    }
                    if(window?.gtag){
                        window?.gtag("event", "sign_in", {
                            method:"username-password"
                        });
                    }
                    setLoading('');
                }, 500);
            } else {
                if (res.status === 412) {
                    presentAlert(res.data.join('<br />'));
                } else {
                    presentAlert(res.message);
                }
                setLoading('');
            }
        });
    }

    return (
        <IonPage id="Login">
            <IonContent fullscreen={false}>
                <IonLoading open={loading ? true : false} message={loading} onCancel={() => setLoading('')} />

                <div className="p-4">
                    <img className="mb-2" src={images.Logo} alt="Chicken Republic" style={{ width: 50 }} />
                    <h2 className="mb-2"><b>Welcome Back!</b></h2>
                    {/* <div>&nbsp;</div> */}
                    <Form form={form} layout="vertical" onFinish={submit} className="mt-4s">
                        {code ? (
                            <div>
                                <div className="mb-5">One time code has been sent to <span className="primary">{vv.username}</span>.</div>
                                <Form.Item name="code" rules={[{ required: true, message: 'Code is required' },]}>
                                    <Input type="number" size="large" className="text-center" placeholder="• • • • • •" style={{ letterSpacing: 10 }} />
                                </Form.Item>
                                <div>&nbsp;</div>

                                <Button type="submit" variant="secondary btn-block btn-md">
                                    Verify &amp; Continue
                                </Button>
                                <div>&nbsp;</div>

                                <div className="text-center">
                                    Didn't get the code? {count < 60 ? `Resend in ${60 - count} secs` : <b onClick={() => submitLogin({})}>RESEND NOW</b>}.
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="text-muteds mb-5">Log in to your account.</div>
                                <PhoneNumber {...props} form={form} />
                                <Form.Item label="Password" name="password" className="mb-2" rules={[{ required: true, message: 'Field is required' },]}>
                                    <Input.Password type="password" size="large" placeholder="• • • • • • • •" />
                                </Form.Item>
                                <div className="small" onClick={() => props.history.push('/reset')}>Forgot Password?</div>
                                <div>&nbsp;</div>

                                <Button type="submit" variant="secondary btn-block btn-md text-uppercases">
                                    Sign in
                                </Button>
                                <div>&nbsp;</div>

                                <div className="text-centers mt-2 text-muted small" onClick={() => props.history.push('/signup')}>
                                    Don't have account?&nbsp; <b className="primary">Sign up</b>
                                </div>
                            </>
                        )}
                    </Form>
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </IonContent>
        </IonPage>
    );
}

export default Login;