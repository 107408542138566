import React from 'react';
import { images } from 'utils';

const Empty = ({ text, style }) => {

    return (
        <React.Fragment>
            <div id="Empty" className="d-flex justify-content-center align-items-center text-center" style={{ height: '50vh', ...style }}>
                <div>
                    <img src={images.Logo} alt="Logo" height="120" style={{ opacity: .4 }} />
                    <div>{text}</div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Empty;