import React from 'react';
import { IonContent, IonPage, IonRefresher, IonRefresherContent } from '@ionic/react';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { RouteComponentProps } from 'react-router';
import { axius, func, types } from 'utils';
import { Empty, Header } from 'components';
import { _store, _update } from '_Store';
import { Form, Input, Modal, notification, Spin } from 'antd';
import { Button } from 'react-bootstrap';

import './_User.scss';

interface Props extends RouteComponentProps, types.StateProps { };
const Wallet: React.FC<Props> = props => {
    const { __auth: { user } } = _store.useState();

    const [form] = Form.useForm();

    const [fund, setFund] = React.useState(false);
    const [data, setData] = React.useState<types.Payment[]>([]);
    const [loading, setLoading] = React.useState({ page: true, modal: false });

    let refresher: any = null;

    React.useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = () => {
        setLoading(sl => ({ ...sl, page: true }));
        window.scroll(0, 0);
        Promise.all([
            axius.get(`users/${user.uuid}`),
            axius.get(`payments`, { type: 'wallet', limit: 20 }),
        ]).then(res => {
            res[0].status === 200 && _update.auth('user', res[0].data);
            setData(res[1].data || []);
            setLoading(sl => ({ ...sl, page: false }));
            refresher && refresher.detail.complete();
        });
    }

    const submit = (v: any) => {
        v['type'] = 'wallet';
        v['description'] = `Wallet funding`;
        v['operation'] = `+`;
        setLoading(sl => ({ ...sl, modal: true }));
        axius.post(`payments`, v).then(res => {
            if (res.status === 200) {
                if (func.isMobile) {
                    const browser = InAppBrowser.create(res.gateway.checkout_url);
                    browser.on('loadstop').subscribe((event) => {
                        if (event.url.includes('/webhook/')) {
                            browser.close();
                            props.history.replace(`/webhook/${event.url.replace('https://', '').split('/')[2]}`);
                            // window.location.reload();
                        }
                    });
                } else {
                    func.redirect(res.gateway.checkout_url);
                }
            } else {
                notification.error(res);
                setLoading(sl => ({ ...sl, modal: false }));
            }
        });
    }

    const close = () => {
        form.resetFields();
        setFund(false);
    }

    return (
        <IonPage id="Wallet">
            <Header {...props} showSearch={false} />

            <IonContent fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={(e) => {
                    refresher = e;
                    getData();
                }}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <div
                    className="px-4 py-4 title bg-img mx-4 mt-2 mt-4 d-flex align-items-center-"
                    style={{ backgroundImage: `url(assets/img/wallet.jpg)`, height: 'auto', borderRadius: 8, boxShadow: '#917676 2px 3px 8px' }}
                >
                    <div style={{ width: '100%' }}>
                        <div className="d-flex justify-content-between text-white">
                            <div className="small">Available Balance</div>
                            <div className="small" onClick={() => setFund(true)}>
                                <i className="fa fa-plus-circle" /> Fund Wallet
                            </div>
                        </div>
                        <div className="fw-600 mt-2 secondary" style={{ fontSize: 25 }}>₦{func.format.number(user.wallet)}</div>
                        <div className="text-uppercase text-center fs-20 mt-3 fw-600 text-white" style={{ opacity: .4 }}>{user.name}</div>
                    </div>
                </div>

                <div className="p-4">
                    {loading.page && [...Array(8)].map((_, i) => (
                        <div key={i} className="skeleton mb-2" style={{ borderRadius: 7, height: 83 }} />
                    ))}

                    {!loading.page && data.length === 0 && (
                        <Empty text="No transactions found." style={{}} />
                    )}

                    {!loading.page && data.length > 0 && data.map((item) => (
                        <div key={item.id} className="mb-2 px-3 py-3" style={{ backgroundColor: 'rgba(255, 204, 0, .2)', borderRadius: 4 }}>
                            <div>
                                {item.order && item.order?.id && (
                                    <span className="fw-500 underline primary" onClick={() => props.history.push(`/user/orders/${item.order?.id}`)}>Order #{item.order?.id} </span>
                                )}
                                {item.description}
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <div className="small text-muted">{func.format.date(item.crdate)}</div>
                                <div className={`
                                        ${item.status === 0 && 'text-muted'} 
                                        ${item.status === 1 && item.operation === '+' && 'success'} 
                                        ${item.status === 1 && item.operation === '-' && 'danger'}
                                        fw-500 fs-20-
                                    `
                                }
                                >{item.operation}₦{func.format.number(item.amount)}</div>
                            </div>
                        </div>
                    ))}
                </div>

                <Modal open={fund} title="Fund Wallet" width="70vw" centered footer={null} onCancel={close}>
                    <Spin spinning={loading.modal} indicator={<span><i className="fa fa-spin fa-circle-notch" /></span>}>
                        <Form form={form} layout="vertical" onFinish={submit} className="pt-4">
                            <Form.Item name="amount" label="Amount to top-up" rules={[func.requiredInput]}>
                                <Input type="number" size="large" prefix="₦" />
                            </Form.Item>
                            <Button type="submit" variant="secondary btn-block btn-md mt-2">
                                Submit
                            </Button>
                        </Form>
                    </Spin>
                </Modal>
            </IonContent>
        </IonPage>
    );
};

export default Wallet;
